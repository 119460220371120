// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const defaultLanguage = 'el';

export const encryptionKey = 'niebwfi&2e2dDW(#435';
export const baseUrl = process.env.REACT_APP_BASE_URL;

export const tokenKey = 'token';
export const refreshTokenKey = 'refresh_token';
export const localeKey = 'locale';

export const cultureCategoryID = 1;
export const tourismCategoryID = 2;
export const routesCategoryID = 3;

export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
export const sengridApiKey = process.env.SENDGRID_API_KEY;
