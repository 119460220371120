import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useStore } from '../../../Stores/GlobalStore';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { capitalize, Grid } from '@mui/material';
import Section from 'Components/Section/Section';
import SectionTitle from 'Components/SectionTitle/SectionTitle';
import TextSection from 'Components/TextSection/TextSection';
import SectionSubtitle from 'Components/SectionSubtitle/SectionSubtitle';
import PreviewCard from 'Components/PreviewCard/PreviewCard';
import PreviewCarousel from 'Components/PreviewCarousel/PreviewCarousel';
import CultureIcon from '../../../assets/images/culture.svg';
import TourismIcon from '../../../assets/images/tourism-enviroment.svg';
import DiadromesIcon from '../../../assets/images/diadromes.svg';
import Head from 'MainAppComponents/Head/Head';
import MainBannerWithTitle from 'Components/MainBannerWithTitle/MainBannerWithTitle';

const Area = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { areaId } = useParams();
    const { areasStore } = useStore();
    const { getAreaById, area, getCategoriesByAreaId, getRoutesByAreaId, politismos, tourismos, diadromes } = areasStore;

    useEffect(() => {
        getAreaById(areaId);
    }, [areaId]);

    useEffect(() => {
        getCategoriesByAreaId(areaId);
        getRoutesByAreaId(areaId);
    }, [area]);

    if (!area || !area?.id) {
        return null;
    }

    return (
        <>
            <Head title={area?.[`name${capitalize(i18n.language)}`]} />
            <Grid container>
                {area?.areaImages?.length > 0 ? (
                    <Grid item xs={12} className="h-full relative">
                        <MainBannerWithTitle
                            imageGallery={area?.areaImages}
                            mainTitle={area[`name${capitalize(i18n.language)}`]}
                            upperTitle={area[`subTitle${capitalize(i18n.language)}`]}
                        />
                    </Grid>
                ) : null}
            </Grid>

            <Section className="pt-5" padded>
                <SectionSubtitle text={capitalize(t('common.Sustainability'))} />
                <SectionTitle
                    firstText={`${i18n.language === 'el' ? 'Λίγα Λόγια' : 'Few Words'}`}
                    secondText={`${i18n.language === 'el' ? 'για την' : 'about'} ${area[`name${capitalize(i18n.language)}`]}`}
                />
                <TextSection>
                    <h5 className="text-left text-justify">
                        <p className="py-2 leading-normal">{`${area[`text${capitalize(i18n.language)}`]}`}</p>
                    </h5>
                </TextSection>
            </Section>

            <Section className="pt-5" padded>
                <SectionSubtitle text={capitalize(t('common.Sustainability'))} />
                <SectionTitle
                    firstText={`${i18n.language === 'el' ? 'Ξεκινήστε την' : 'Begin your'}`}
                    secondText={`${i18n.language === 'el' ? 'Εξερεύνησή σας' : 'Exploration'} `}
                />
            </Section>

            <Section padded>
                <Grid
                    container
                    pb={8}
                    sx={{
                        backgroundColor: theme.palette.common.white
                    }}
                >
                    <Grid item xs={12} md={4}>
                        <PreviewCard
                            nameEl={t('dashboard.Politismos')}
                            nameEn={t('dashboard.Politismos')}
                            mainImage={area.mainImage}
                            areaImages={[area.areaImages]}
                            urlTo={`/area/${area.id}/category/1`}
                            moreText={t('common.ShowPois')}
                            icon={CultureIcon}
                            darken
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <PreviewCarousel
                            slideshowImages={
                                politismos?.some((item) => item?.poiImages?.length > 0)
                                    ? politismos?.map((item) => item?.poiImages?.map((image) => image)).flat()
                                    : area?.areaImages
                            }
                            opacity={0.8}
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    pb={8}
                    sx={{
                        backgroundColor: theme.palette.common.white
                    }}
                >
                    <Grid item xs={12} md={8}>
                        <PreviewCarousel
                            slideshowImages={
                                tourismos?.some((item) => item?.poiImages?.length > 0)
                                    ? tourismos?.map((item) => item?.poiImages?.map((image) => image)).flat()
                                    : area?.areaImages
                            }
                            opacity={0.8}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PreviewCard
                            nameEl={t('dashboard.Tourismos')}
                            nameEn={t('dashboard.Tourismos')}
                            mainImage={area.mainImage}
                            areaImages={[area.areaImages]}
                            urlTo={`/area/${area.id}/category/2`}
                            moreText={t('common.ShowPois')}
                            icon={TourismIcon}
                            darken
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    pb={8}
                    sx={{
                        backgroundColor: theme.palette.common.white
                    }}
                >
                    <Grid item xs={12} md={4}>
                        <PreviewCard
                            nameEl={t('dashboard.Diadromes')}
                            nameEn={t('dashboard.Diadromes')}
                            mainImage={area.mainImage}
                            areaImages={[area.areaImages]}
                            urlTo={`/area/${area.id}/routes`}
                            moreText={t('common.ShowPois')}
                            icon={DiadromesIcon}
                            darken
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <PreviewCarousel
                            slideshowImages={
                                diadromes?.some((item) => item?.routeImages?.length > 0)
                                    ? diadromes?.map((item) => item?.routeImages?.map((image) => image)).flat()
                                    : area?.areaImages
                            }
                            opacity={0.8}
                        />
                    </Grid>
                </Grid>
            </Section>
        </>
    );
};
export default observer(Area);
