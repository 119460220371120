import { localeKey } from '../../Config/constant';

export function getLocaleFromLocalStorage() {
    return localStorage.getItem(localeKey);
}

export function saveLocaleToLocalStorage(locale) {
    localStorage.setItem(localeKey, locale);
}

export function removeLocaleFromLocalStorage() {
    localStorage.removeItem(localeKey);
}
