import Helmet from 'react-helmet';

import { useTranslation } from 'react-i18next';

const Head = ({ title }) => {
    const { t } = useTranslation();
    return (
        <Helmet>
            <title>{title || t('pages.fallbackTitle')}</title>
            <meta name={title || t('pages.fallbackTitle')} content={title || t('pages.fallbackTitle')} />
        </Helmet>
    );
};

export default Head;
