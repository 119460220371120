import { observer } from 'mobx-react';
import { Fragment, useMemo, useState } from 'react';
import { useStore } from '../../../Stores/GlobalStore';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { capitalize, Grid, Button } from '@mui/material';
import Map from 'Components/Map/Map';
import Section from 'Components/Section/Section';
import SectionTitle from 'Components/SectionTitle/SectionTitle';
import SectionSubtitle from 'Components/SectionSubtitle/SectionSubtitle';
import Head from 'MainAppComponents/Head/Head';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const Category = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { areaId, categoryId } = useParams();
    const navigate = useNavigate();

    const { dataStore } = useStore();
    const { areas, categories, subcategories, pois } = dataStore;
    const [activeSubCategory, setActiveSubCategory] = useState(null);

    // derived state
    //
    const area = areas?.find((item) => item?.id?.toString() === areaId);
    const category = categories?.find((item) => item?.id?.toString() === categoryId);
    const areaName = area?.[`name${capitalize(i18n.language)}`] || '';
    const categoryName = category?.[`name${capitalize(i18n.language)}`] || '';

    const relatedPOIs = useMemo(() => {
        if (areaId && categoryId) {
            return pois
                ?.filter((point) => point?.categoryId?.toString() === categoryId)
                ?.filter((point) => point?.areaId?.toString() === areaId);
        }
    }, [pois, categoryId, areaId]);
    const subCategories = [...new Set(relatedPOIs?.map((obj) => obj.subCategoryId))];
    const filteredSubCategories = subcategories.filter(({ id }) => subCategories.includes(id));
    const filteredPOIs = !activeSubCategory
        ? relatedPOIs
        : relatedPOIs?.filter((poi) => poi?.subCategoryId?.toString() === activeSubCategory?.toString());

    const handleListItemClick = (event, index) => {
        setActiveSubCategory(index === activeSubCategory ? null : index);
    };

    const renderCategoryPoiTitle = () => {
        const firstText = t('misc.CategoryTitleFirstText');
        const secondText = categoryId === '1' ? t('misc.CulturePoisSecondText') : t('misc.TourismPoisSecondText');
        return (
            <>
                <SectionSubtitle className="pb-2 pt-5" text={areaName} />
                <SectionTitle firstText={firstText} secondText={secondText} />
            </>
        );
    };

    const content = (
        <>
            <Section className="mb-[-15px]" padded>
                {renderCategoryPoiTitle()}
            </Section>

            <div className="sm:px-2 lg:px-40 pb-4">
                <Button
                    startIcon={<ArrowBackIosNewIcon />}
                    onClick={() => navigate(`/area/${areaId}`)}
                    style={{
                        color: theme.palette.customPrimary.main,
                        fontSize: 16,
                        fontWeight: theme.typography.fontWeightLightBold,
                        textTransform: 'none'
                    }}
                >
                    {i18n.language === 'el' ? 'Επιστροφή στην περιοχή' : 'Return to area'}
                </Button>
            </div>

            <Section padded className="bg-[#0F2454] mb-4">
                <div className="flex flex-wrap justify-center items-center py-4">
                    {filteredSubCategories?.length > 0
                        ? filteredSubCategories?.map((subcategory, index) => (
                              <Fragment key={subcategory?.id}>
                                  <Button
                                      sx={{
                                          color:
                                              activeSubCategory === subcategory?.id
                                                  ? theme.palette.customSecondary.main
                                                  : theme.palette.common.white,
                                          '&:hover': {
                                              outline: `1px solid ${theme.palette.customSecondary.main}`
                                          },
                                          fontSize: 20,
                                          margin: 1
                                      }}
                                      key={subcategory?.id}
                                      selected={activeSubCategory === subcategory?.id}
                                      onClick={(event) => handleListItemClick(event, subcategory?.id)}
                                      variant="text"
                                  >
                                      {subcategory[`name${capitalize(i18n.language)}`]}
                                  </Button>
                                  {index !== subCategories?.length - 1 && <span className="mb-2 text-white">|</span>}
                              </Fragment>
                          ))
                        : null}
                </div>
            </Section>

            <Section padded className="pb-20 pt-12">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Map
                            area={area}
                            areaId={areaId}
                            categoryId={categoryId}
                            geolocation={{ geoLat: area?.geoLat, geoLong: area?.geoLong }}
                            selectedSubcategoryPois={filteredPOIs}
                        />
                    </Grid>
                </Grid>
            </Section>
        </>
    );

    return (
        <>
            <Head title={`${areaName} - ${categoryName}`} />
            {content}
        </>
    );
};
export default observer(Category);
