import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useStore } from '../../../Stores/GlobalStore';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Button, capitalize, Grid } from '@mui/material';
import { toJS } from 'mobx';
import Section from 'Components/Section/Section';
import SectionTitle from 'Components/SectionTitle/SectionTitle';
import TextSection from 'Components/TextSection/TextSection';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Head from 'MainAppComponents/Head/Head';
import MainBannerWithTitle from 'Components/MainBannerWithTitle/MainBannerWithTitle';
import RelevantPoiButton from 'MainAppComponents/RelevantPoiButton/RelevantPoiButton';

const PoiDetails = () => {
    const theme = useTheme();
    const { poiStore } = useStore();
    const { getExtendedPoiById, getPoisByAreaAndSubCategoryId } = poiStore;

    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const poi = location?.state?.poi;
    const { areaId, categoryId, id } = useParams();

    const [poidetails, setPoiDetail] = useState({});
    const [poiExtended, setPoiExtended] = useState({});
    const [relatedPois, setRelatedPois] = useState([]);

    useEffect(() => {
        if (poi !== undefined) {
            setPoiDetail(poi);
            getExtendedPoiById(id);
        } else {
            getExtendedPoiById(id).then((res) => {
                setPoiDetail(toJS(res?.extendedPoiDto));
            });
        }
    }, [id]);

    useEffect(() => {
        setPoiExtended(poiStore.poiDetails);
    }, [poiStore.poiDetails]);

    useEffect(() => {
        if (poidetails?.subCategoryId) {
            getPoisByAreaAndSubCategoryId(areaId, poidetails?.subCategoryId).then((res) => {
                setRelatedPois(toJS(res?.poiListDto));
            });
        }
    }, [poidetails?.subCategoryId]);

    const filteredPOIs = relatedPois
        .filter((poi) => poi?.subCategoryId === poidetails?.subCategoryId)
        .filter((poi) => poi?.id !== poiExtended?.id);

    const getEmbeddedUrlLink = (url) => {
        const videoIDPattern = /(youtu.be\/|youtube\.com\/(watch\?(.*&)?v=|(embed|v|watch?v|watch\?v|)\/))([^?&"'>]+)/;
        const matches = url?.match(videoIDPattern);
        // If matched, the fifth group of the regex pattern will contain the video ID
        const match = matches && matches[5] ? matches[5] : null;
        return `https://www.youtube.com/embed/${match}?autoplay=1&mute=1`;
    };

    const getCategoryDescription = (category) => {
        const firstText = `${t('misc.ReturnTo')} ${t('misc.CategoryTitleFirstText').toLowerCase()}`;
        let secondText = '';

        switch (category?.id) {
            case 1:
                secondText = t('misc.CulturePoisSecondText');
                return `${firstText} ${secondText}`;
            case 2:
                secondText = t('misc.TourismPoisSecondText');
                return `${firstText} ${secondText}`;
            default:
                return `${firstText} ${secondText}`;
        }
    };

    const getTitleByCategory = (category) => {
        switch (category?.id) {
            case 1:
                return `${i18n.language === 'el' ? 'για το σημείο πολιτισμού' : 'about the cultural point'}`;
            case 2:
                return `${i18n.language === 'el' ? 'για το σημείο τουρισμού & περιβάλλοντος' : 'about the tourism & environment point'}`;
            default:
                return '';
        }
    };

    const YTstyle = {
        margin: '0',
        padding: '0',
        height: '100%',
        width: '100%'
    };
    const containerStyle = {
        width: '100%',
        height: '80vh',
        position: 'relative'
    };

    if (!poiExtended?.id) {
        return null;
    }

    return (
        <>
            <Head title={poidetails?.[`name${capitalize(i18n.language)}`]} />
            <Grid container>
                <Grid item xs={12} className="h-full relative">
                    <MainBannerWithTitle
                        imageGallery={poiExtended?.poiImages}
                        mainTitle={poiExtended[`name${capitalize(i18n.language)}`]}
                        upperTitle={`${poiExtended?.subCategory[`name${capitalize(i18n.language)}`]} | 
                                ${poiExtended?.area[`name${capitalize(i18n.language)}`]}`}
                    />
                </Grid>
            </Grid>

            <Section className="pt-5" padded>
                <Section className="pt-2 pb-12">
                    <Button
                        startIcon={<ArrowBackIosNewIcon />}
                        onClick={() => navigate(`/area/${areaId}/category/${categoryId}`)}
                        style={{
                            color: theme.palette.customPrimary.main,
                            fontSize: 16,
                            fontWeight: theme.typography.fontWeightLightBold,
                            textTransform: 'none'
                        }}
                    >
                        {getCategoryDescription(poiExtended.category)}
                    </Button>
                </Section>

                <SectionTitle
                    firstText={`${i18n.language === 'el' ? 'Λίγα Λόγια' : 'Few Words'}`}
                    secondText={getTitleByCategory(poiExtended.category)}
                />
                <TextSection>
                    <h5 className="text-left text-justify">
                        <p className="py-2 leading-normal">{`${poiExtended[`text${capitalize(i18n.language)}`]}`}</p>
                    </h5>
                </TextSection>

                {poiExtended?.videoLink !== '' ? (
                    <Grid item sm={12} className="mx-auto pb-8 my-5">
                        <div style={containerStyle}>
                            <iframe
                                src={getEmbeddedUrlLink(poiExtended?.videoLink)}
                                style={YTstyle}
                                title="fx."
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            />
                        </div>
                    </Grid>
                ) : null}
            </Section>

            {filteredPOIs?.length > 0 && (
                <Section padded className="pb-20">
                    <SectionTitle
                        firstText={t('misc.Choose')}
                        secondText={`${i18n.language === 'el' ? 'από την κατηγορία' : 'from category'} ${
                            poiExtended?.subCategory?.[`name${capitalize(i18n.language)}`]
                        }`}
                    />
                    <Grid container spacing={2}>
                        {filteredPOIs.map((poi) => (
                            <RelevantPoiButton key={poi?.id} poi={poi} />
                        ))}
                    </Grid>
                </Section>
            )}
        </>
    );
};

export default observer(PoiDetails);
