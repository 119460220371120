import { observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { capitalize, Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../Stores/GlobalStore';
import { useNavigate, useParams } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { toJS } from 'mobx';
import KmlMap from 'Components/KmlMap/KmlMap';
import Head from 'MainAppComponents/Head/Head';
import Section from 'Components/Section/Section';
import SectionSubtitle from 'Components/SectionSubtitle/SectionSubtitle';
import SectionTitle from 'Components/SectionTitle/SectionTitle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const RoutesPage = () => {
    const categoryId = 3;
    const navigate = useNavigate();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { areaId } = useParams();
    const { areasStore, categoryStore } = useStore();
    const { area, diadromes, getRoutesBySubCategoryId, areasPoisList, getCategoriesByAreaId, getAreaById, getRoutesByAreaId } = areasStore;
    const { categoryList } = categoryStore;
    const [subCategories, setSubCategories] = useState([]);
    const [activeSubCategory, setActiveSubCategory] = useState(0);
    const [subCategoryRoutes, setSubCategoryRoutes] = useState([]);
    const areaName = area?.[`name${capitalize(i18n.language)}`] || '';
    const category = categoryList?.find((item) => item?.id === categoryId);
    const categoryName = category?.[`name${capitalize(i18n.language)}`] || '';

    function getUniqueRouteSubactegories(diadromes) {
        const allSubcategories = [];
        diadromes.forEach((diadromi) => allSubcategories.push(diadromi?.subCategory));
        const uniqueRouteSubcategories = [...new Map(allSubcategories.map((item) => [item?.id, item])).values()];
        return uniqueRouteSubcategories;
    }
    useEffect(() => {
        setSubCategories(getUniqueRouteSubactegories(toJS(diadromes)));
        setActiveSubCategory(diadromes[0]?.subCategory?.id);
    }, [diadromes]);

    useEffect(() => {
        if (activeSubCategory === 0) {
            setSubCategoryRoutes(toJS(diadromes));
        } else {
            setSubCategoryRoutes(toJS(getRoutesBySubCategoryId(activeSubCategory)));
        }
    }, [activeSubCategory, diadromes]);

    const handleListItemClick = (event, index) => {
        setActiveSubCategory(index === activeSubCategory ? 0 : index);
    };

    useEffect(() => {
        if (areasPoisList.length === 0) {
            getAreaById(+areaId);
            getCategoriesByAreaId(+areaId);
        }

        if (diadromes.length === 0) {
            getRoutesByAreaId(+areaId);
        }
    }, [areaId]);

    const renderCategoryPoiTitle = () => {
        const firstText = t('misc.CategoryTitleFirstText');
        const secondText = t('misc.RoutesPoisSecondText');
        return (
            <>
                <SectionSubtitle className="pb-2 pt-5" text={areaName} />
                <SectionTitle firstText={firstText} secondText={secondText} />
            </>
        );
    };

    const content = (
        <>
            <Section padded className="mb-[-15px]">
                {renderCategoryPoiTitle()}
            </Section>

            <div className="sm:px-2 lg:px-40 pb-4">
                <Button
                    startIcon={<ArrowBackIosNewIcon />}
                    onClick={() => navigate(`/area/${areaId}`)}
                    style={{
                        color: theme.palette.customPrimary.main,
                        fontSize: 16,
                        fontWeight: theme.typography.fontWeightLightBold,
                        textTransform: 'none'
                    }}
                >
                    {i18n.language === 'el' ? 'Επιστροφή στην περιοχή' : 'Return to area'}
                </Button>
            </div>

            <Section padded className="bg-[#0F2454] mb-4">
                <div className="flex flex-wrap justify-center items-center py-4">
                    {subCategories?.length > 0 &&
                        subCategories?.map((subcategory, index) => (
                            <Fragment key={subcategory?.id}>
                                <Button
                                    sx={{
                                        color:
                                            activeSubCategory === subcategory?.id
                                                ? theme.palette.customSecondary.main
                                                : theme.palette.common.white,
                                        '&:hover': {
                                            outline: `1px solid ${theme.palette.customSecondary.main}`
                                        },
                                        fontSize: 20,
                                        margin: 1
                                    }}
                                    key={subcategory?.id}
                                    selected={activeSubCategory === subcategory?.id}
                                    onClick={(event) => handleListItemClick(event, subcategory?.id)}
                                    variant="text"
                                >
                                    {subcategory[`name${capitalize(i18n.language)}`]}
                                </Button>
                                {index !== subCategories?.length - 1 && <span className="mb-2 text-white">|</span>}
                            </Fragment>
                        ))}
                </div>
            </Section>

            <Section padded className="pb-20 pt-12">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <KmlMap routes={subCategoryRoutes} area={area} />
                    </Grid>
                </Grid>
            </Section>
        </>
    );

    return (
        <>
            <Head title={`${areaName} - ${categoryName}`} />
            {content}
        </>
    );
};

export default observer(RoutesPage);
