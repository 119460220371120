import { Typography, useTheme } from '@mui/material';

const TextSection = ({ asideText, children }) => {
    const theme = useTheme();

    const aside = asideText ? (
        <aside
            className="relative xl:float-right rounded-lg mb-10 mr-8 xl:mb-[40px] xl:ml-[40px] xl:w-[350px]"
            style={{ boxShadow: `30px 30px ${theme.palette.customSecondary.main}` }}
        >
            <div
                className="bg-white p-[20px] rounded-lg min-h-[200px] flex items-center"
                style={{ outline: `1px solid ${theme.palette.customPrimary.main}` }}
            >
                <Typography color="customPrimary.main" fontSize={18} lineHeight={1.4}>
                    {asideText}
                </Typography>
            </div>
        </aside>
    ) : null;

    return (
        <section className="flex">
            <span
                className="display-none md:display-block md:min-w-[124px] lg:min-w-[174px] border-solid border-t-2 mt-3 mr-0 md:mr-10"
                style={{ borderColor: theme.palette.grey[500] }}
            />
            <section className="">
                {aside}
                {children}
                {/* <Typography color="customText.main">{text}</Typography> */}
            </section>
        </section>
    );
};

export default TextSection;
