const Section = ({ className, children, id, padded, style }) => {
    const paddingClass = padded ? 'sm:px-2 lg:px-40' : '';
    const classes = `${className || ''} ${paddingClass}`;

    return (
        <section id={id} className={classes} style={style}>
            {children}
        </section>
    );
};

export default Section;
