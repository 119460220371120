import { Button, Typography, capitalize } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

const RelevantPoiButton = ({ route, poi }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    // console.log('route', route, 'poi', poi);

    const handleClick = () => {
        if (route) {
            navigate(`/area/${route.area.id}/routes/${route.id}`);
        } else if (poi) {
            navigate(`/area/${poi.areaId}/category/${poi.categoryId}/poidetails/${poi.id}`, {
                state: {
                    poi
                }
            });
        }
    };

    const buttonTitle = route ? route[`name${capitalize(i18n.language)}`] : poi[`name${capitalize(i18n.language)}`];

    return (
        <Button
            variant="contained"
            onClick={handleClick}
            className="px-4 py-2 rounded-md shadow-md m-2 text-lg"
            sx={{
                backgroundColor: theme.palette.customPrimary.main,
                whiteSpace: 'break-spaces',
                maxWidth: '100%',
                '&:hover': {
                    opacity: 0.8,
                    backgroundColor: theme.palette.customPrimary.main
                }
            }}
        >
            <div
                style={{
                    maxWidth: '100%',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word'
                }}
            >
                <Typography
                    variant="h2"
                    color="white"
                    fontSize="18px"
                    flex={1}
                    sx={{
                        textTransform: 'uppercase'
                    }}
                >
                    <span lang="el">{buttonTitle}</span>
                </Typography>
            </div>
        </Button>
    );
};

export default RelevantPoiButton;
