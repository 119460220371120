import { createContext, useContext } from 'react';
import AreasStore from './AreasStore';
import CategoryStore from './CategoryStore';
import PoiStore from './PoiStore';
import RouteStore from './RouteStore';
import ThemeStore from './ThemeStore';
import DataStore from './DataStore';

export const globalStore = {
    dataStore: new DataStore(),
    areasStore: new AreasStore(),
    categoryStore: new CategoryStore(),
    poiStore: new PoiStore(),
    routeStore: new RouteStore(),
    themeStore: new ThemeStore()
};

export const GlobalStoreContext = createContext(globalStore);

export function useStore() {
    return useContext(GlobalStoreContext);
}
