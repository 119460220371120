import { observer } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import Guest from './Guest';
import GuestRoutes from '../Routes/GuestRoutes';
import ScrollToTop from 'CustomHooks/ScrollToTop';

const AppContent = () => (
    <BrowserRouter>
        <ScrollToTop />
        <Guest>
            <GuestRoutes />
        </Guest>
    </BrowserRouter>
);

export default observer(AppContent);
