/* eslint-disable react/no-unescaped-entities */
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import TextSection from 'Components/TextSection/TextSection';
import SectionTitle from 'Components/SectionTitle/SectionTitle';

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <>
            <section id="project" className="py-5 sm:px-2 lg:px-40">
                <SectionTitle firstText={t('pages.privacyPolicy')} secondText={t('common.ForTheProject')} />

                <TextSection>
                    <h3 className="font-bold pb-2 leading-snug">{t('pages.termsOfUse')}</h3>

                    <h5 className="font-bold pb-2">Άρθρο 1 - Εισαγωγή</h5>
                    <h5 className="text-justify">
                        <p className="py-2 leading-normal">
                            Η εταιρία «ΤΕΛΕΣΤΩ ΕΠΕ» η οποία εδρεύει στο Χολαργό, Αττικής και εκπροσωπείται νόμιμα, συμμετέχει στην ομάδα
                            υλοποίηση (σ.σ. εφεξής «εταίροι») του έργου «Αειφόρος Ανάπτυξη Λιγότερο Αναπτυγμένων Περιοχών με την Δημιουργία
                            Νέων Τουριστικών Πόρων και Προϊόντων μέσω Ανάλυσης, Τεκμηρίωσης, Μοντελοποίησης, Διαχείρισης και Διατήρησης
                            Πολιτιστικού Αποθέματος με χρήση Εφαρμογών ΤΠΕ – ΑΕΙ», που χρηματοδοτείται από την Ενιαία Δράση Κρατικών
                            Ενισχύσεων Έρευνας, Τεχνολογικής Ανάπτυξης & Καινοτομίας - πρόσκληση «ΕΡΕΥΝΩ – ΔΗΜΙΟΥΡΓΩ – ΚΑΙΝΟΤΟΜΩ», με κωδικό
                            έργου Τ2ΕΔΚ-01278. Για τις ανάγκες του έργου η «ΤΕΛΕΣΤΩ ΕΠΕ» έχει αναπτύξει την ηλεκτρονική σελίδα στο διαδίκτυο{' '}
                            <a
                                href="https://aei-touristikh.telesto-projects.eu/"
                                rel="noreferrer"
                                target="_blank"
                                className="hover:cursor-pointer"
                                style={{
                                    textDecoration: 'none'
                                }}
                            >
                                https://aei-touristikh.telesto-projects.eu/
                            </a>
                            , καθώς και την εφαρμογή «AEI Touristiki» σε Googleplay & Appstore (σ.σ. εφεξής «app») και η «ΤΕΛΕΣΤΩ ΕΠΕ»
                            αποτελεί την αποκλειστική και νόμιμη δικαιούχου τους.
                        </p>
                        <p className="py-2 leading-normal">
                            Ο επισκέπτης/χρήστης των υπηρεσιών του παρόντος ιστοτόπου{' '}
                            <a
                                href="https://aei-touristikh.telesto-projects.eu/"
                                rel="noreferrer"
                                target="_blank"
                                className="hover:cursor-pointer"
                                style={{
                                    textDecoration: 'none'
                                }}
                            >
                                https://aei-touristikh.telesto-projects.eu/
                            </a>{' '}
                            και της εφαρμογής app κατανοεί και αποδέχεται το σύνολο των σελίδων/υπηρεσιών που παρέχονται από τον ιστότοπο
                            και την εφαρμογή app, ενώ η «ΤΕΛΕΣΤΩ ΕΠΕ» δια του παρόντος αποποιείται οποιασδήποτε ευθύνης σχετικής με την
                            περιορισμένη χρονική διάρκεια, τη διαγραφή, την κακή απόδοση ή την αδυναμία ηλεκτρονικής αποθήκευσης
                            οποιωνδήποτε δεδομένων (data) των χρηστών ή/και οποιουδήποτε περιεχομένου προσωπικών σελίδων/υπηρεσιών. Η
                            «ΤΕΛΕΣΤΩ ΕΠΕ» δεσμεύεται να διασφαλίσει την ιδιωτικότητα των χρηστών του ιστοτόπου και της εφαρμογής app.
                        </p>
                    </h5>

                    <h5 className="font-bold pb-2">Άρθρο 2 - Άδεια Χρήσης - Πνευματικά Δικαιώματα</h5>
                    <h5 className="text-justify">
                        <p className="py-2 leading-normal">
                            Εκτός των ρητά αναφερόμενων εξαιρέσεων (πνευματικά δικαιώματα τρίτων, συνεργατών και φορέων), όλο το περιεχόμενο
                            του ιστοτόπου και της εφαρμογής app, συμπεριλαμβανομένων videos, εικόνων, γραφικών, φωτογραφιών, σχεδίων,
                            κειμένων, των παρεχομένων υπηρεσιών και γενικά όλων των αρχείων του δικτυακού τόπου, αποτελούν πνευματική
                            ιδιοκτησία, κατατεθειμένα σήματα, διασχηματισμοί, διακριτικά γνωρίσματα και σήματα υπηρεσιών του ιστοτόπου{' '}
                            <a
                                href="https://aei-touristikh.telesto-projects.eu/"
                                rel="noreferrer"
                                target="_blank"
                                className="hover:cursor-pointer"
                                style={{
                                    textDecoration: 'none'
                                }}
                            >
                                https://aei-touristikh.telesto-projects.eu/
                            </a>{' '}
                            και της εφαρμογής app και προστατεύονται από τις σχετικές διατάξεις του ελληνικού δικαίου, του ευρωπαϊκού
                            δικαίου και των διεθνών συμβάσεων και συνθηκών. Ό,τι άλλο περιλαμβάνεται στις ηλεκτρονικές σελίδες του ιστοτόπου
                            και στο app, εφόσον αποτελεί κατοχυρωμένο σήμα και προϊόν πνευματικής ιδιοκτησίας τρίτων ανάγεται στη δική τους
                            σφαίρα ευθύνης. Συνεπώς, κανένα εξ αυτών δύναται να αποτελέσει εν όλω ή εν μέρει αντικείμενο πώλησης,
                            αντιγραφής, τροποποίησης, αναπαραγωγής, αναδημοσίευσης ή να "φορτωθεί", να μεταδοθεί ή να διανεμηθεί με
                            οποιονδήποτε τρόπο. Τα προϊόντα ή υπηρεσίες που αναφέρονται στις ηλεκτρονικές σελίδες του παρόντος κόμβου και
                            στην εφαρμογή app και φέρουν τα σήματα των αντίστοιχων οργανισμών, εταιρειών, συνεργατών φορέων, ενώσεων,
                            αποτελούν δική τους πνευματική και βιομηχανική ιδιοκτησία και συνεπώς οι φορείς αυτοί φέρουν τη σχετική ευθύνη.
                            Ο χρήστης κατανοεί και αποδέχεται ότι δεν του παρέχεται το δικαίωμα να αναπαράγει, αντιγράφει, πωλεί, μεταπωλεί
                            ή/και εκμεταλλεύεται εμπορικά με οποιονδήποτε τρόπο σύνολο ή μέρος του περιεχομένου του ιστοτόπου και της
                            εφαρμογής app, άλλως αποδέχεται πως ευθύνεται αποκλειστικά για αποζημίωση των ανωτέρω.
                        </p>
                    </h5>

                    <h5 className="font-bold pb-2">Άρθρο 3 - Δηλώσεις Αποποίησης και Περιορισμοί ευθύνης</h5>
                    <h5 className="text-justify">
                        <p className="py-2 leading-normal">
                            O ιστότοπος{' '}
                            <a
                                href="https://aei-touristikh.telesto-projects.eu/"
                                rel="noreferrer"
                                target="_blank"
                                className="hover:cursor-pointer"
                                style={{
                                    textDecoration: 'none'
                                }}
                            >
                                https://aei-touristikh.telesto-projects.eu/
                            </a>{' '}
                            και η εφαρμογή app έχουν ενημερωτική φύση και δεν ευθύνονται για οποιασδήποτε μορφής ζημία υποστεί ο χρήστης των
                            σελίδων και της εφαρμογής app μέσω των υπηρεσιών, επιλογών και περιεχομένων του, καθώς αναγνωρίζει και
                            αποδέχεται ότι προβαίνει στη χρήση αυτών με δική του πρωτοβουλία.
                        </p>
                        <p className="py-2 leading-normal">
                            επιλογές και τα περιεχόμενα θα παρέχονται χωρίς διακοπή, χωρίς σφάλματα και ότι τα λάθη θα διορθώνονται. Επίσης,
                            δεν εγγυώνται ότι το site ή οι εξυπηρετητές (servers) μέσω των οποίων αυτά τίθενται στη διάθεσή των
                            χρηστών/μελών, δεν περιέχουν "ιούς" ή άλλα επιζήμια συστατικά. Ο ιστότοπος και η εφαρμογή app δεν εγγυάται σε
                            καμία περίπτωση την ορθότητα, την πληρότητα ή και διαθεσιμότητα των περιεχομένων, σελίδων, υπηρεσιών, επιλογών ή
                            τα αποτελέσματά τους. Το κόστος των ενδεχόμενων διορθώσεων ή εξυπηρετήσεων, το αναλαμβάνει ο επισκέπτης/χρήστης
                            και σε καμία περίπτωση ο ιστότοπος αυτός και η εφαρμογή app και η «ΤΕΛΕΣΤΩ ΕΠΕ» ή οι εταίροι που τα
                            διαχειρίζονται.
                        </p>
                        <p className="py-2 leading-normal">
                            Σε περίπτωση δυσλειτουργίας ή προσωρινής μη λειτουργίας των συστημάτων και του δικτύου της «ΤΕΛΕΣΤΩ ΕΠΕ»,
                            συμφωνείται ρητώς ότι ουδεμία ευθύνη θα φέρει αυτή ως προς την αποκατάσταση οποιασδήποτε τυχόν θετικής ή
                            αποθετικής ζημίας υποστεί ο χρήστης/επισκέπτης εκ του λόγου αυτού. Στην ως άνω περίπτωση, συμφωνείται ότι η
                            «ΤΕΛΕΣΤΩ ΕΠΕ» δικαιούται, προς αποφυγή του κινδύνου παραπλάνησης των χρηστών της να ειδοποιήσει εγγράφως τον
                            εκάστοτε χρήστη/επισκέπτη και ταυτόχρονα να προχωρήσει στην προσωρινή διακοπή της παρεχόμενης προς αυτόν
                            υπηρεσίας, μέχρι το χρόνο αποκατάστασης της βλάβης. Στην προκειμένη περίπτωση η «ΤΕΛΕΣΤΩ ΕΠΕ» ουδεμία ευθύνη
                            φέρει για την αποκατάσταση της τυχόν θετικής ή αποθετικής ζημίας του χρήστη/ επισκέπτη, για το σύνολο του
                            χρονικού διαστήματος που δεν παρέχεται η υπηρεσία.
                        </p>
                    </h5>

                    <h5 className="font-bold pb-2">Άρθρο 4 - Σύνδεσμοι προς δικτυακούς τόπους (links)</h5>
                    <h5 className="text-justify">
                        <p className="py-2 leading-normal">
                            O ιστότοπος{' '}
                            <a
                                href="https://aei-touristikh.telesto-projects.eu/"
                                rel="noreferrer"
                                target="_blank"
                                className="hover:cursor-pointer"
                                style={{
                                    textDecoration: 'none'
                                }}
                            >
                                https://aei-touristikh.telesto-projects.eu/
                            </a>{' '}
                            και η εφαρμογή app δεν ελέγχουν τη διαθεσιμότητα, το περιεχόμενο, την πολιτική προστασίας των προσωπικών
                            δεδομένων, την ποιότητα και πληρότητα των υπηρεσιών άλλων διαδικτυακών τόπων στις οποίες τυχόν παραπέμπει μέσω
                            “συνδέσμων” (hyperlinks) (εφεξής καλουμένων για λόγους συντομίας ως «σύνδεσμοι»). Οι σύνδεσμοι έχουν τοποθετηθεί
                            αποκλειστικά για τη διευκόλυνση των χρηστών του δικτυακού τόπου, ενώ οι δικτυακοί τόποι στους οποίους
                            παραπέμπουν υπόκεινται στους αντίστοιχους των δικτυακών αυτών τόπων, όρους χρήσης. Η τοποθέτηση των συνδέσμων
                            δεν αποτελεί ένδειξη έγκρισης ή αποδοχής του περιεχομένου των αντίστοιχων δικτυακών τόπων, ενώ η «ΤΕΛΕΣΤΩ ΕΠΕ»
                            ουδεμία φέρει ευθύνη για το περιεχόμενο τους και οιαδήποτε ζημία προκύψει από τη χρήση.
                        </p>
                    </h5>

                    <h5 className="font-bold pb-2">Άρθρο 5 – Εφαρμοστέο Δίκαιο</h5>
                    <h5 className="text-justify">
                        <p className="py-2 leading-normal">
                            Οι παρόντες Όροι Χρήσης διέπονται από το Ελληνικό Δίκαιο, από τις ισχύουσες σε αυτό νομοθετικές διατάξεις της
                            Ευρωπαϊκής Ένωσης και από τις Διεθνείς Συνθήκες και ερμηνεύονται σύμφωνα με τους κανόνες της καλής πίστης, των
                            συναλλακτικών ηθών και του κοινωνικού και οικονομικού σκοπού του δικαιώματος. Εξάλλου ρητά ορίζεται με το παρόν
                            ότι στην περίπτωση που ήθελε εγερθεί κατά της δικαιούχου «ΤΕΛΕΣΤΩ ΕΠΕ» των site/app, οιαδήποτε αγωγή, αξίωση, ή
                            άλλη δικαστική αξίωση ή διοικητική διαδικασία και πηγάζει από την παράβαση εκ μέρους του εκάστοτε χρήστη
                            οιασδήποτε φύσεως δικαιωμάτων τρίτων, υποχρεούται ο χρήστης όπως αφενός παρέμβει στη δικαστική ή διοικητική
                            διαδικασία και αφετέρου αποζημιώσει πλήρως την δικαιούχο «ΤΕΛΕΣΤΩ ΕΠΕ», σε περίπτωση κατά την οποία η τελευταία
                            υποχρεωθεί σε καταβολή αποζημίωσης ή οιασδήποτε άλλης δαπάνης με υπαιτιότητα του χρήστη.
                        </p>
                        <p className="py-2 leading-normal">
                            Αρμόδια Δικαστήρια για την επίλυση κάθε διαφοράς που τυχόν προκύψει μεταξύ των συμβαλλομένων μερών σχετικά με
                            την ερμηνεία ή εκτέλεση των Όρων Χρήσης, είναι αυτά των Αθηνών.
                        </p>
                    </h5>
                </TextSection>

                <SectionTitle firstText="Συμμόρφωση GDPR" secondText="" />

                <TextSection>
                    <h5 className="font-bold pb-2">ΓΕΝΙΚΑ</h5>
                    <p className="py-2 leading-normal">
                        Η χρήση των ηλεκτρονικών σελίδων και υπηρεσιών πού παρέχονται στον επισκέπτη/χρήστη, από τον ιστότοπο
                        https://aei-touristikh.telesto-projects.eu/ ιδιοκτησίας της «Αειφόρος Ανάπτυξη Λιγότερο Αναπτυγμένων Περιοχών με την
                        Δημιουργία Νέων Τουριστικών Πόρων και Προϊόντων μέσω Ανάλυσης, Τεκμηρίωσης, Μοντελοποίησης, Διαχείρισης και
                        Διατήρησης Πολιτιστικού Αποθέματος με χρήση Εφαρμογών ΤΠΕ – ΑΕΙ» εφεξής «η εταιρεία», προϋποθέτει την ανεπιφύλακτη
                        συμφωνία σας με την Πολιτική Απορρήτου περί Προστασίας Προσωπικών Δεδομένων του παρόντος ιστότοπου. Συνεπώς, ο
                        επισκέπτης/χρήστης οφείλει να διαβάσει προσεκτικά τα περιεχόμενα αυτής της σελίδας, πριν από τη χρήση των υπηρεσιών
                        του διαδικτυακού μας τόπου. Αν δεν συμφωνεί, θα πρέπει να τον εγκαταλείψει και να ΜΗΝ κάνει καμία χρήση των
                        υπηρεσιών και του περιεχομένου του.
                    </p>
                    <p className="py-2 leading-normal">
                        Αυτή η πολιτική απορρήτου μπορεί να αλλάζει από καιρό σε καιρό σύμφωνα με τη νομοθεσία ή τις εξελίξεις στον κλάδο.
                        Δεν θα ενημερώσουμε ρητά τους πελάτες μας ή τους χρήστες του ιστότοπου μας για τις αλλαγές αυτές. Αντ̓ αυτού, σας
                        συνιστούμε να ελέγχετε περιστασιακά αυτή τη σελίδα για τυχόν αλλαγές της παρούσας πολιτικής απορρήτου. Η
                        εξακολούθηση της χρήσης του ιστότοπου https://aei-touristikh.telesto-projects.eu/ ακόμη και μετά τις όποιες αλλαγές
                        στην Πολιτική Απορρήτου, σημαίνει την ανεπιφύλακτη αποδοχή των όρων αυτών εκ μέρους σας.
                    </p>

                    <h5 className="font-bold pb-2">Προστασία προσωπικών δεδομένων</h5>

                    <p className="py-2 leading-normal">
                        Συλλέγουμε και επεξεργαζόμαστε τα προσωπικά σας δεδομένα, μόνο όταν είναι απολύτως απαραίτητο.
                    </p>
                    <p className="py-2 leading-normal">
                        Δεν πρόκειται ποτέ να πουλήσουμε, να ενοικιάσουμε, να διανείμουμε, ή να δημοσιοποιήσουμε με οποιονδήποτε τρόπο, τα
                        προσωπικά σας δεδομένα.
                    </p>
                    <p className="py-2 leading-normal">
                        Εάν είστε κάτω των 16 ετών, ΠΡΕΠΕΙ να έχετε τη συγκατάθεση των γονέων σας πριν κάνετε χρήση των υπηρεσιών του
                        παρόντος ιστότοπου.
                    </p>

                    <h5 className="font-bold pb-2">Μπορούν να γνωστοποιηθούν τα στοιχεία μου σε τρίτους/άλλες εταιρείες;</h5>
                    <p className="py-2 leading-normal">
                        Τα δεδομένα αυτά τα επεξεργαζόμαστε τηρώντας την εφαρμογή της Οδηγίας της ΕΕ για την προστασία των δεδομένων του
                        1995 (DPD), καθώς επίσης και του Κανονισμού για την γενική προστασία δεδομένων της ΕΕ (GDPR) με εφαρμογή από τις 25
                        Μαΐου 2018. Η εκ μέρους σας καταχώριση των προσωπικών σας δεδομένων, σημαίνει ότι συναινείτε τα δεδομένα αυτά να
                        χρησιμοποιούνται από την εταιρεία μας για τους λόγους που αναφέρονται στην Δήλωση Προστασίας Προσωπικών Δεδομένων
                        (Πολιτική απορρήτου)
                    </p>

                    <h5 className="font-bold pb-2">Σχετική νομοθεσία</h5>

                    <p className="py-2 leading-normal">
                        Παράλληλα με τα εσωτερικά συστήματα πληροφορικής της εταιρείας μας, ο ιστότοπος αυτός έχει σχεδιαστεί έτσι ώστε να
                        συμμορφώνεται όσον αφορά την προστασία των προσωπικών δεδομένων των χρηστών, με τις νομοθεσίες/κανονισμούς που
                        ακολουθούν:
                    </p>

                    <ol>
                        <li>Οδηγία της ΕΕ για την προστασία των δεδομένων του 1995 (DPD)</li>
                        <li>Κανονισμός 2018 για την γενική προστασία δεδομένων της ΕΕ (GDPR)</li>
                    </ol>

                    <h5 className="font-bold pb-2">Προσωπικές πληροφορίες που συλλέγει αυτός ο ιστότοπος και γιατί τις συλλέγουμε</h5>

                    <p className="py-2 leading-normal">
                        Αυτός ο ιστότοπος συλλέγει και χρησιμοποιεί προσωπικές πληροφορίες για τους ακόλουθους λόγους:
                    </p>

                    <h5 className="font-bold pb-2">Σύνδεσμοι ηλεκτρονικού ταχυδρομείου</h5>

                    <p className="py-2 leading-normal">
                        Σε περίπτωση που επιλέξετε να επικοινωνήσετε μαζί μας χρησιμοποιώντας κάποιο σύνδεσμο του ηλεκτρονικού μας
                        ταχυδρομείου, κανένα από τα δεδομένα που παρέχετε δεν θα αποθηκευτεί από αυτόν τον ιστότοπο ή θα μεταβιβαστεί ή θα
                        υποβληθεί σε επεξεργασία από οποιονδήποτε τρίτο φορέα επεξεργασίας δεδομένων. Αντίθετα, τα δεδομένα αυτά θα μας
                        σταλούν με ένα μήνυμα ηλεκτρονικού ταχυδρομείου μέσω του πρωτοκόλλου SMTP (Simple Mail Transfer Protocol). Οι SMTP
                        διακομιστές μας, προστατεύονται από πρωτόκολλο ασφαλείας TLS (μερικές φορές γνωστό και ως SSL), που σημαίνει ότι το
                        περιεχόμενο ηλεκτρονικού ταχυδρομείου κρυπτογραφείται πριν να αποσταλεί μέσω του διαδικτύου. Το περιεχόμενο του
                        ηλεκτρονικού ταχυδρομείου αποκρυπτογραφείται από τους τοπικούς μας υπολογιστές και συσκευές.
                    </p>

                    <h5 className="font-bold pb-2">Ασφάλεια</h5>
                    <p className="py-2 leading-normal">
                        Το πρωτόκολλο SSL (Secure Sockets Layer), είναι σήμερα το παγκόσμιο standard στο διαδίκτυο για την πιστοποίηση
                        διαδικτυακών τόπων (websites) στους διαδικτυακούς χρήστες και για την κρυπτογράφηση στοιχείων μεταξύ των
                        διαδικτυακών χρηστών και των διαδικτυακών εξυπηρετητών (web servers). Μία κρυπτογραφημένη SSL επικοινωνία απαιτεί
                        όλες τις πληροφορίες που αποστέλλονται μεταξύ ενός πελάτη (client) και ενός εξυπηρετητή (server) να κρυπτογραφούνται
                        από το λογισμικό αποστολής και να αποκρυπτογραφούνται από το λογισμικό αποδοχής, προστατεύοντας έτσι προσωπικές
                        πληροφορίες κατά τη μεταφορά τους. Επιπλέον, όλες οι πληροφορίες που αποστέλλονται με το πρωτόκολλο SSL,
                        προστατεύονται από έναν μηχανισμό που αυτόματα εξακριβώνει εάν τα δεδομένα έχουν αλλαχτεί κατά την μεταφορά.
                    </p>

                    <h5 className="font-bold pb-2">Σχετικά με τα cookies</h5>

                    <h5 className="font-bold pb-2">Τί είναι το cookie;</h5>

                    <p className="py-2 leading-normal">
                        Ο όρος «cookie» αναφέρεται σε ένα μικρό αρχείο δεδομένων που αποτελείται αποκλειστικά από μια σειρά πληροφοριών σε
                        μορφή κειμένου, το οποίο ο ιστότοπος μεταφέρει στο πρόγραμμα πλοήγησης ιστού που βρίσκεται στο σκληρό δίσκο του
                        υπολογιστή σας, είτε προσωρινά καθ’ όλη τη διάρκεια της επίσκεψής σας, είτε μερικές φορές για μεγαλύτερες περιόδους,
                        ανάλογα με τον τύπο του cookie. Τα cookies εκτελούν διάφορες λειτουργίες (για παράδειγμα, σας ξεχωρίζουν από τους
                        άλλους επισκέπτες του ίδιου ιστοτόπου ή θυμούνται ορισμένα πράγματα για εσάς, όπως τις προτιμήσεις σας) και
                        χρησιμοποιούνται από τους περισσότερους ιστότοπους για να βελτιώσουν την εμπειρία σας ως χρήστη τους.{' '}
                    </p>

                    <p className="py-2 leading-normal">
                        Κάθε cookie είναι μοναδικό για το πρόγραμμα πλοήγησής σας και περιέχει ορισμένες ανώνυμες πληροφορίες. Ένα cookie
                        συνήθως περιέχει το όνομα του πεδίου από όπου προήλθε το cookie, τη «διάρκεια ζωής» του cookie καθώς και μία τιμή
                        (συνήθως με τη μορφή ενός τυχαίως παραχθέντα μοναδικού αριθμού).
                    </p>

                    <h5 className="font-bold pb-2">Τύποι cookies</h5>

                    <p className="py-2 leading-normal">
                        Οι βασικοί τύποι των cookies που ενδεχομένως να χρησιμοποιούν οι ιστότοποι περιγράφονται παρακάτω
                    </p>

                    <ul>
                        <li>
                            <h5>Cookies επίσκεψης (session cookies)</h5>
                            <p className="py-2 leading-normal">
                                Πρόκειται για προσωρινά cookies που παραμένουν στο αρχείο των cookies του προγράμματος πλοήγησης της
                                συσκευής σας μόνο κατά τη διάρκεια της επίσκεψής σας και διαγράφονται όταν κλείσετε το πρόγραμμα πλοήγησης.
                            </p>
                        </li>
                        <li>
                            <h5>Μόνιμα cookies (persistent cookies)</h5>
                            <p className="py-2 leading-normal">
                                Αυτά παραμένουν στο αρχείο των cookies του προγράμματος πλοήγησης της συσκευής σας ακόμα και αφότου κλείσει
                                το πρόγραμμα πλοήγησης, μερικές φορές για ένα έτος ή και παραπάνω (η ακριβής διάρκεια παραμονής εξαρτάται
                                από τη διάρκεια ζωής κάθε cookie). Τα μόνιμα cookies χρησιμοποιούνται όταν ο διαχειριστής του ιστοτόπου
                                ενδεχομένως χρειάζεται να γνωρίζει ποιος είστε για παραπάνω από μία επισκέψεις (π.χ. για να θυμάται το όνομα
                                χρήστη σας ή τις προτιμήσεις σας ως προς την παραμετροποίηση του ιστοτόπου).
                            </p>
                        </li>
                        <li>
                            <h5>Cookies πρώτου μέρους (First-party cookies)</h5>
                            <p className="py-2 leading-normal">
                                Πρόκειται για cookies που εγκαθίστανται στο πρόγραμμα πλοήγησης και/ή στο σκληρό δίσκο της συσκευής σας από
                                τον ιστότοπο τον οποίον επισκέπτεστε. Αυτό περιλαμβάνει την ανάθεση ενός μοναδικού αναγνωριστικού κωδικού σε
                                εσάς με σκοπό την παρακολούθηση της πλοήγησής σας στον ιστότοπο. Οι διαχειριστές ιστοτόπων συχνά
                                χρησιμοποιούν cookies πρώτου μέρους για τη διαχείριση των επισκέψεων και για σκοπούς αναγνώρισης.
                            </p>
                        </li>
                        <li>
                            <h5>Cookies τρίτων μερών (Third-party cookies)</h5>
                            <p className="py-2 leading-normal">
                                Πρόκειται για cookies που χρησιμοποιούνται από τρίτα μέρη, όπως π.χ. κοινωνικά δίκτυα, για να παρακολουθούν
                                τις επισκέψεις σας στους διάφορους ιστότοπους στους οποίους διαφημίζονται. Ο διαχειριστής του ιστοτόπου δεν
                                έχει έλεγχο σε αυτά τα cookies τρίτων μερών.
                            </p>
                        </li>
                    </ul>

                    <h5 className="font-bold pb-2">Τα cookies σε αυτό τον ιστότοπο και πώς να τα διαχειριστείτε</h5>

                    <p className="py-2 leading-normal">
                        Σε αυτόν τον ιστότοπο, χρησιμοποιούμε τα cookies για να βελτιώσουμε την εμπειρία της χρήσης του ιστοτόπου από τους
                        επισκέπτες του. Η λειτουργία του ιστοτόπου θα επηρεαστεί σημαντικά αν απενεργοποιήσετε ή αν δεν αποδεχθείτε την
                        χρήση των cookies.
                    </p>
                    <p className="py-2 leading-normal">
                        Ακολουθούν πληροφορίες σχετικά με τα cookies τρίτων μερών που χρησιμοποιούμε σε αυτόν τον ιστότοπο,
                        συμπεριλαμβανομένου του τρόπου απενεργοποίησής τους και της επίδρασης της απενεργοποίησης στη λειτουργία του
                        ιστοτόπου. Αν επιθυμείτε περισσότερες πληροφορίες για τον τρόπο διαχείρισης ορισμένων τύπων cookies,
                        συμπεριλαμβανομένου του τρόπου ελέγχου ή διαγραφής τους, παρακαλούμε επισκεφτείτε τη διεύθυνση:{' '}
                        <a href="http://www.aboutcookies.org" data-rel="external">
                            {' '}
                            www.aboutcookies.org{' '}
                        </a>
                        .
                    </p>

                    <h5 className="font-bold pb-2">Cookies από παρόχους υπηρεσίας βίντεο (Google, Vimeo, DailyMotion κλπ)</h5>

                    <p className="py-2 leading-normal">
                        Οι πάροχοι αυτοί μπορεί να τοποθετήσουν cookies στη συσκευή σας, αν παρακολουθείτε στον ιστότοπό μας βίντεο τα οποία
                        μας παρέχουν ως εξωτερική υπηρεσία.
                    </p>
                    <p className="py-2 leading-normal">
                        Αν απενεργοποιήσετε αυτά τα cookies, ενδεχομένως να μην μπορείτε να δείτε τα ενσωματωμένα βίντεο από τον ιστότοπό
                        μας.
                    </p>

                    <h5 className="font-bold pb-2">Σχετικά με τον διακομιστή αυτού του ιστοτόπου</h5>

                    <p className="py-2 leading-normal">
                        Όλη η διαδικτυακή κίνηση (μεταφορά αρχείων) μεταξύ αυτού του ιστότοπου και του προγράμματος περιήγησής σας, είναι
                        κρυπτογραφημένη και μεταφέρεται μέσω του πρωτοκόλλου HTTPS κάνοντας χρήση του SSL (Secure Sockets Layer).
                    </p>

                    <h5 className="font-bold pb-2">Οι επεξεργαστές δεδομένων τρίτου μέρους μας</h5>

                    <p className="py-2 leading-normal">
                        Χρησιμοποιούμε έναν αριθμό τρίτων φορέων για την επεξεργασία προσωπικών δεδομένων για εμάς. Αυτοί οι φορείς έχουν
                        επιλεγεί προσεκτικά ώστε να συμμορφώνονται με τη νομοθεσία που αναφέρθηκε στο παρόν κείμενο.
                    </p>

                    <h5 className="font-bold pb-2">Παραβιάσεις δεδομένων</h5>

                    <p className="py-2 leading-normal">
                        Για όσες προσωπικές σας πληροφορίες αποθηκευτούν στη βάση δεδομένων μας, θα ληφθούν όλα τα απαραίτητα μέτρα για την
                        διασφάλισή τους.
                    </p>
                    <p className="py-2 leading-normal">
                        Θα αναφέρουμε κάθε τυχόν παράνομη παραβίαση της βάσης δεδομένων αυτού του ιστοτόπου ή της βάσης δεδομένων κάθε
                        τρίτου φορέα επεξεργασίας δεδομένων σε οποιονδήποτε και σε όλους τους άμεσα ενδιαφερομένους όπως και στις και αρχές
                        εντός 72 ωρών από την παραβίαση, εφόσον είναι προφανές ότι τα προσωπικά δεδομένα που είναι αποθηκευμένα σε
                        αναγνωρίσιμη μορφή, έχουν κλαπεί.
                    </p>

                    <h5 className="font-bold pb-2">Διαγραφή δεδομένων</h5>

                    <p className="py-2 leading-normal">
                        Σε περίπτωση που επιλέξετε να επικοινωνήσετε μαζί μας χρησιμοποιώντας κάποιο σύνδεσμο του ηλεκτρονικού μας
                        ταχυδρομείου και δεν επιθυμείτε την επιπλέον επικοινωνία μετά μαζί μας, μπορείτε να στείλετε στο ίδιο email με θέμα
                        "ΔΙΑΓΡΑΦΗ" για να προβούμε στην άμεση διαγραφή στοιχείων σας από τον email (SMTP) server μας.
                    </p>
                </TextSection>
            </section>
        </>
    );
};

export default observer(PrivacyPolicy);
