import { requests } from '../Common/HttpClient/HttpClient';

const { get } = requests;

export function getAllAreas() {
    return get('/api/Area/GetExtendedAreaList');
}

export function getAreaByIdfromApi(id) {
    return get(`/api/Area/GetById/${id}`);
}

export function getPoisByAreaId(areaId) {
    // return get(`/api/Poi/GetByAreaId/${areaId}`);
    return get(`/api/Poi/GetExtendedPoiByAreaId/${areaId}`);
}

export function getRoutesByAreaId(areaId) {
    // return get(`/api/Poi/GetByAreaId/${areaId}`);
    return get(`/api/Route/GetExtendedRoutesByAreaId/${areaId}`);
}

export function getByAreaAndCategoryId(areaId, categoryId) {
    return get(`/api/Poi/GetByAreaAndCategoryId?areaId=${areaId}&categoryId=${categoryId}`);
}

export function getImageByAreaId(areaId) {
    return get(`/api/AreaImage/GetByAreaId/${areaId}`);
}

export function getAllAreaImages() {
    return get(`/api/AreaImage/GetAllAreaImages`);
}
