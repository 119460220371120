import Carousel from 'react-bootstrap/Carousel';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';

const StyledCarousel = styled.div`
    .carousel-inner {
        border-radius: 16px;
    }
`;

const PreviewCarousel = ({ slideshowImages, opacity = 1 }) => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        setImages(slideshowImages);
    }, [slideshowImages]);

    return (
        <StyledCarousel className="relative h-[450px] block rounded-xl m-2 cursor-pointer hover:shadow-2xl">
            <Carousel className="w-full h-full rounded-xl">
                {images?.map((image, index) => (
                    <Carousel.Item
                        className="h-full bg-black rounded-xl"
                        key={index}
                        style={{
                            borderRadius: '4px'
                        }}
                    >
                        <div
                            className="d-block w-100 m-auto h-full rounded-xl"
                            style={{
                                backgroundImage: `url(${image.url})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                opacity
                            }}
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
        </StyledCarousel>
    );
};

export default PreviewCarousel;
