import { action, makeObservable, observable } from 'mobx';
import { getExtendedRouteById, getRouteList } from '../Api/RouteAgent';

export default class RouteStore {
    routeDetails = [];

    allRoutes = [];

    constructor() {
        makeObservable(this, {
            routeDetails: observable,
            allRoutes: observable,
            getAllRoutes: action,
            getExtendedRouteById: action
        });

        this.getAllRoutes();
    }

    getAllRoutes = async () => {
        this.allRoutes = await getRouteList();
        return this.allRoutes;
    };

    getExtendedRouteById = async (routeId) => {
        this.routeDetails = await getExtendedRouteById(routeId);
        return this.routeDetails;
    };
}
