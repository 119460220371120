import { Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import MainCarousel from '../../Components/MainCarousel/MainCarousel';
import { useStore } from '../../Stores/GlobalStore';
import { toJS } from 'mobx';
import FourColumnGrid from 'Components/FourColumnGrid/FourColumnGrid';
import StatsBanner from 'Components/StatsBanner/StatsBanner';
import ProjectSummary from 'Components/ProjectSummary/ProjectSummary';
import { useTranslation } from 'react-i18next';
import MainBannerWithTitle from 'Components/MainBannerWithTitle/MainBannerWithTitle';

const Dashboard = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [statsMap, setStatsMap] = useState();
    const [imageGallery, setImageGallery] = useState([]);
    const { dataStore } = useStore();
    const { areas, categories, pois, routes } = dataStore;

    const poisandRoutes = useMemo(() => [...pois, ...routes], [pois, routes]);

    useEffect(() => {
        if (!categories?.length || !poisandRoutes?.length) {
            return;
        }

        const totalsObject = {};

        categories?.forEach((category) => {
            totalsObject[category?.id] = 0;
        });
        poisandRoutes?.forEach((poi) => {
            totalsObject[poi?.categoryId] += 1;
        });

        setStatsMap(totalsObject);
    }, [poisandRoutes, categories]);

    const categoriesWithStats = !statsMap ? categories : categories?.map((cat) => ({ ...cat, poiCount: statsMap[cat?.id] }));

    useEffect(() => {
        const images = [];
        areas?.forEach((area) => {
            area.areaImages.forEach((areaImage) => {
                if (areaImage?.isMain) {
                    images.push(toJS(areaImage));
                }
            });
            setImageGallery(images);
        });
    }, [areas]);

    return (
        <>
            <Grid container className="h-4/6">
                <Grid item xs={12} className="h-full relative">
                    <MainBannerWithTitle
                        imageGallery={imageGallery}
                        mainTitle={t('common.TouristPlatformAEI')}
                        upperTitle={t('common.ExploreGreece')}
                    />
                </Grid>
            </Grid>
            <ProjectSummary />
            <StatsBanner bgImage={imageGallery[0]} items={categoriesWithStats} />
            <FourColumnGrid className="py-5" items={areas} />
        </>
    );
};

export default observer(Dashboard);
