import { Typography, useTheme } from '@mui/material';

const SectionSubtitle = ({ className, text }) => {
    const theme = useTheme();
    return (
        <Typography
            variant="h3"
            textTransform="uppercase"
            color="customSecondary.main"
            letterSpacing={3}
            fontSize={18}
            fontWeight={600}
            className={className || 'py-3'}
        >
            <span lang="el">{text}</span>
        </Typography>
    );
};

export default SectionSubtitle;
