import { observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import epanek from 'assets/images/dashboard/epanek.png';
import consortium from 'assets/images/dashboard/consortium.jpg';
import googlePlayEl from 'assets/images/google-play-badge-el.svg';
import googlePlayEn from 'assets/images/google-play-badge-en.svg';
import appStoreEl from 'assets/images/apple-store-badge-el.svg';
import appStoreEn from 'assets/images/apple-store-badge-en.svg';
import '../../App.css';

const Footer = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    return (
        <section id="contact">
            <Grid container spacing={3} className="mt-2 py-4 px-16" style={{ background: theme.palette.customSecondary.main }}>
                <Grid item xs={12} md={4} lg={4}>
                    <div className="flex flex-col justify-center items-start gap-2">
                        <Typography
                            gutterBottom
                            variant="h4"
                            className="text-slate-50"
                            fontWeight={theme.typography.fontWeightBold}
                            sx={{
                                textTransform: 'uppercase'
                            }}
                        >
                            {t('dashboard.Contact')}
                        </Typography>
                        <Typography gutterBottom variant="h5" className="text-slate-50" fontWeight={theme.typography.fontWeightRegular}>
                            {t('dashboard.Coordinator')}
                        </Typography>
                        <Typography gutterBottom variant="h5" className="text-slate-50" fontWeight={theme.typography.fontWeightRegular}>
                            Email:{' '}
                            <a
                                href="mailto:aei@mail.ntua.gr"
                                className="hover:cursor-pointer text-slate-50"
                                style={{
                                    textDecoration: 'none'
                                }}
                            >
                                {' '}
                                aei@mail.ntua.gr{' '}
                            </a>
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Typography gutterBottom variant="h5" className="sm:mt-8 text-slate-50" fontWeight={theme.typography.fontWeightRegular}>
                        {t('dashboard.Website')}{' '}
                        <a
                            href="http://aei-project.ntua.gr/"
                            rel="noreferrer"
                            target="_blank"
                            className="hover:cursor-pointer text-slate-50"
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                            aei-project.ntua.gr
                        </a>
                    </Typography>
                    <Typography gutterBottom variant="h5" className="sm:mt-8 text-slate-50" fontWeight={theme.typography.fontWeightRegular}>
                        <a
                            href="/privacy-policy"
                            rel="noreferrer"
                            target="_blank"
                            className="hover:cursor-pointer text-slate-50"
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                            {t('pages.privacyPolicy')}
                        </a>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Typography
                        gutterBottom
                        variant="h3"
                        className="text-slate-50"
                        sx={{
                            textTransform: 'uppercase',
                            marginBottom: '1rem'
                        }}
                    >
                        {t('dashboard.DownloadApp')}
                    </Typography>
                    <div className="flex sm:flex-row flex-col justify-start items-center gap-4 w-[80%]">
                        <Box
                            component="img"
                            src={i18n.language === 'el' ? googlePlayEl : googlePlayEn}
                            alt="google play"
                            className="hover:cursor-pointer h-auto sm:max-w-[135px] max-w-[125px] min-w-[125px]"
                            onClick={() => window.open('https://play.google.com/store/apps/details?id=com.aeitouristiki.app', '_blank')}
                        />
                        <Box
                            component="img"
                            src={i18n.language === 'el' ? appStoreEl : appStoreEn}
                            alt="app store"
                            className="hover:cursor-pointer h-auto max-w-[150px] sm"
                            onClick={() => window.open('https://apps.apple.com/us/app/aei-touristiki/id6473789906', '_blank')}
                        />
                    </div>
                </Grid>
            </Grid>
            <div
                className="flex flex-col md:flex-row justify-center items-center gap-4 w-full pb-2 px-16"
                style={{
                    background: theme.palette.background.default
                }}
            >
                <div className="flex flex-col justify-center items-center gap-2 w-full md:w-5/6 max-w-[80%]">
                    <div className="flex justify-center items-center gap-2 md:flex-row flex-col">
                        <Box
                            component="img"
                            src={consortium}
                            marginX={theme.spacing(8)}
                            sx={{ width: '100%', height: 'auto' }}
                            alt="consortium"
                            className="md:w-[50%] w-[100%]"
                        />
                        <Box
                            component="img"
                            src={epanek}
                            marginX={theme.spacing(8)}
                            sx={{ width: '100%', height: 'auto' }}
                            alt="epanek"
                            className="md:w-[50%] w-[100%]"
                        />
                    </div>

                    <Typography
                        gutterBottom
                        fontWeight={theme.typography.fontWeightRegular}
                        align="center"
                        fontSize={theme.typography.fontSize * 0.6}
                        style={{
                            color: theme.palette.customText.main
                        }}
                    >
                        Το έργο ΑΕΙ χρηματοδοτείται στα πλαίσια της Ενιαίας Δράσης Κρατικών Ενισχύσεων Έρευνας, Τεχνολογικής Ανάπτυξης &
                        Καινοτομίας στο πλαίσιο της πρόσκλησης «ΕΡΕΥΝΩ – ΔΗΜΙΟΥΡΓΩ – ΚΑΙΝΟΤΟΜΩ» Κωδικός ΟΠΣ 2076, Επιχειρησιακό Πρόγραμμα
                        «Ανταγωνιστικότητα Επιχειρηματικότητα και Καινοτομία», ΕΣΠΑ 2014-2020. ΚΩΔΙΚΟΣ ΕΡΓΟΥ Τ2ΕΔΚ-01278
                    </Typography>
                </div>
            </div>
        </section>
    );
};

export default observer(Footer);
