import axios from 'axios';
import { baseUrl } from '../../Config/constant';

axios.defaults.baseURL = baseUrl;

const responseBody = (response) => response.data;

// eslint-disable-next-line import/prefer-default-export
export const requests = {
    get: (url) => axios.get(url).then(responseBody),
    post: (url, body, config = {}) => axios.post(url, body, config).then(responseBody),
    patch: (url, body) => axios.patch(url, body).then(responseBody),
    delete: (url, body) => axios.delete(url, body).then(responseBody)
};
