import { CircularProgress, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import MainCard from 'MainAppComponents/MainCard/MainCard';
import Footer from 'MainAppComponents/Footer/footer';
import Header from 'MainAppComponents/Header/Header';
import { useStore } from 'Stores/GlobalStore';
import Head from 'MainAppComponents/Head/Head';

const Page = ({ children, title }) => {
    const theme = useTheme();
    const { dataStore } = useStore();
    const { loadingAppData, fetchError } = dataStore;
    const { t } = useTranslation();

    const loader = (
        <section className="flex justify-center items-center p-20 min-h-[60vh]">
            <CircularProgress sx={{ color: theme.palette.customSecondary.main }} size={40} />
        </section>
    );

    const error = (
        <section className="flex justify-center items-center p-20 min-h-[60vh]">
            <Typography className="text-center" color="customPrimary.main" variant="h1">
                {t('misc.NoData')}
            </Typography>
        </section>
    );

    const resolveContent = () => {
        if (loadingAppData) {
            return loader;
        }

        if (fetchError) {
            return error;
        }

        if (children) {
            return <>{children}</>;
        }

        return null;
    };

    return (
        <>
            <Head title={title} />
            <MainCard contentClass="p-0">
                <Header />
                {resolveContent()}
                <Footer />
            </MainCard>
        </>
    );
};

export default observer(Page);
