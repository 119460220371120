import { requests } from '../Common/HttpClient/HttpClient';

const { get } = requests;

// eslint-disable-next-line import/prefer-default-export
export function getExtendedRouteById(id) {
    return get(`/api/Route/GetExtendedRouteById/${id}`);
}

export function getRouteList() {
    return get('/api/Route/GetRouteList');
}
