import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useStore } from '../../../Stores/GlobalStore';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { capitalize, Grid, Typography, Button } from '@mui/material';
import { toJS } from 'mobx';
import KmlMap from 'Components/KmlMap/KmlMap';
import SectionTitle from 'Components/SectionTitle/SectionTitle';
import Section from 'Components/Section/Section';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import TextSection from 'Components/TextSection/TextSection';
import MainBannerWithTitle from 'Components/MainBannerWithTitle/MainBannerWithTitle';
import RelevantPoiButton from 'MainAppComponents/RelevantPoiButton/RelevantPoiButton';
import Head from 'MainAppComponents/Head/Head';

const RouteDetails = () => {
    const theme = useTheme();
    const { routeStore, areasStore } = useStore();
    const { getExtendedRouteById } = routeStore;
    const { area, getAreaById, getRoutesByAreaId, diadromes } = areasStore;
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const route = location?.state?.route;
    const { areaId, id } = useParams();
    const [routeDetails, setRouteDetails] = useState({});
    const [diadromesList, setDiadromesList] = useState([]);

    const subCategoryRoutes = diadromesList
        .filter((diadromi) => diadromi?.subCategory?.id === routeDetails?.subCategory?.id)
        .filter((route) => route?.id !== routeDetails?.id);

    useEffect(() => {
        getAreaById(areaId);
        getRoutesByAreaId(areaId);
    }, [areaId]);

    useEffect(() => {
        if (route !== undefined) {
            setRouteDetails(route);
        } else {
            getExtendedRouteById(id).then((res) => {
                setRouteDetails(toJS(res?.extendedRouteDto));
            });
        }
    }, [id]);

    useEffect(() => {
        setDiadromesList(toJS(diadromes));
    }, [diadromes]);

    const getEmbeddedUrlLink = (url) => {
        const videoIDPattern = /(youtu.be\/|youtube\.com\/(watch\?(.*&)?v=|(embed|v|watch?v|watch\?v|)\/))([^?&"'>]+)/;
        const matches = url?.match(videoIDPattern);
        // If matched, the fifth group of the regex pattern will contain the video ID
        const match = matches && matches[5] ? matches[5] : null;
        return `https://www.youtube.com/embed/${match}?autoplay=1&mute=1`;
    };

    const getCategoryDescription = () => {
        const firstText = `${t('misc.ReturnTo')} ${t('misc.CategoryTitleFirstText').toLowerCase()}`;
        const secondText = t('misc.RoutesPoisSecondText');
        return `${firstText} ${secondText}`;
    };

    const YTstyle = {
        margin: '0',
        padding: '0',
        height: '100%',
        width: '100%'
    };
    const containerStyle = {
        height: '40vh'
    };

    if (!routeDetails?.id) {
        return null;
    }

    return (
        <>
            <Head title={routeDetails?.[`name${capitalize(i18n.language)}`]} />
            <Grid container>
                <Grid item xs={12} className="h-full relative pt-12">
                    <Section className="flex justify-center pb-4 gap-2">
                        <Typography
                            variant="h2"
                            color="customSecondary.main"
                            sx={{
                                textTransform: 'uppercase'
                            }}
                            className="inline"
                            fontSize="30px"
                        >
                            <span lang="el">{`${routeDetails?.area[`name${capitalize(i18n.language)}`]}`}</span>
                        </Typography>
                        <Typography variant="h2" color="customPrimary.main" className="inline" fontSize="30px">
                            {' - '}
                            {routeDetails[`name${capitalize(i18n.language)}`]}
                        </Typography>
                    </Section>
                </Grid>
            </Grid>

            {Object.keys(routeDetails).length === 0 ? (
                <KmlMap routes={[route]} area={area} />
            ) : (
                <KmlMap routes={[routeDetails]} area={area} />
            )}
            <Grid container>
                {routeDetails?.routeImages?.length > 0 ? (
                    <Grid item xs={12} className="h-full relative">
                        <MainBannerWithTitle
                            imageGallery={routeDetails?.routeImages}
                            mainTitle={routeDetails[`name${capitalize(i18n.language)}`]}
                            upperTitle={`${routeDetails?.subCategory[`name${capitalize(i18n.language)}`]} | 
                                ${routeDetails?.area[`name${capitalize(i18n.language)}`]}`}
                        />
                    </Grid>
                ) : null}
            </Grid>

            <Section className="pt-5" padded>
                <Section className="pb-12">
                    <Button
                        startIcon={<ArrowBackIosNewIcon />}
                        onClick={() => navigate(`/area/${areaId}/routes`)}
                        style={{
                            color: theme.palette.customPrimary.main,
                            fontSize: 16,
                            fontWeight: theme.typography.fontWeightLightBold,
                            textTransform: 'none'
                        }}
                    >
                        {getCategoryDescription()}
                    </Button>
                </Section>

                <SectionTitle
                    firstText={`${i18n.language === 'el' ? 'Λίγα Λόγια' : 'Few Words'}`}
                    secondText={`${i18n.language === 'el' ? 'για την διαδρομή' : 'about the route'}`}
                />
                <TextSection>
                    <h5 className="text-left text-justify">
                        <p className="py-2 leading-normal">{`${routeDetails[`text${capitalize(i18n.language)}`]}`}</p>
                    </h5>
                </TextSection>

                {routeDetails?.routeVideos?.length > 0
                    ? routeDetails?.routeVideos?.map((routeVideo) => (
                          <Grid container spacing={2} className=" pb-8 flex justify-evenly">
                              <Grid item sm={12} className="mx-auto my-5">
                                  <div style={containerStyle}>
                                      <iframe
                                          src={getEmbeddedUrlLink(routeVideo?.videoUrlKey)}
                                          style={YTstyle}
                                          title="fx."
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      />
                                  </div>
                              </Grid>
                          </Grid>
                      ))
                    : null}
            </Section>

            {subCategoryRoutes?.length > 0 && (
                <Section padded className="pb-20">
                    <SectionTitle
                        firstText={t('misc.Choose')}
                        secondText={`${i18n.language === 'el' ? 'από την κατηγορία' : 'from category'} ${
                            routeDetails?.subCategory?.[`name${capitalize(i18n.language)}`]
                        } ${i18n.language === 'el' ? 'διαδρομές' : 'routes'}`}
                    />
                    <Grid container spacing={2}>
                        {subCategoryRoutes.map((route) => (
                            <RelevantPoiButton key={route?.id} route={route} />
                        ))}
                    </Grid>
                </Section>
            )}
        </>
    );
};

export default observer(RouteDetails);
