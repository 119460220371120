import { capitalize, Typography } from '@mui/material';
import PreviewCard from 'Components/PreviewCard/PreviewCard';
import Section from 'Components/Section/Section';
import SectionSubtitle from 'Components/SectionSubtitle/SectionSubtitle';
import SectionTitle from 'Components/SectionTitle/SectionTitle';
import TextSection from 'Components/TextSection/TextSection';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const FourColumnGrid = ({ className, items }) => {
    const { t, i18n } = useTranslation();
    const lang = i18n?.language;
    const [itemsLeft, setItemsLeft] = useState();
    const [itemsRight, setItemsRight] = useState();
    const subtitleText =
        lang === 'el'
            ? 'Ξεκινήστε την εξερεύνηση και ανακαλύψτε τον επόμενο ιδανικό προορισμό για μοναδικές εμπειρίες.'
            : 'Start exploring and discover the next ideal destination for unique experiences.';

    useEffect(() => {
        const leftGridItems = [];
        const rightGridItems = [];

        items?.forEach((item, index) => {
            if ((index + 1) % 4 === 1 || (index + 1) % 4 === 2) {
                leftGridItems.push(item);
            } else {
                rightGridItems.push(item);
            }
        });

        setItemsLeft(leftGridItems);
        setItemsRight(rightGridItems);
    }, [items]);

    return (
        <Section id="areas" padded className={className}>
            <SectionSubtitle text={capitalize(t('common.Sustainability'))} />
            <SectionTitle firstText={t('common.Areas')} secondText={t('common.OfApplication')} />
            <section className="grid grid-cols-2">
                <div className="col-span-1 pt-1">
                    <TextSection>
                        <Typography fontSize={20} lineHeight={1.4}>
                            {subtitleText}
                        </Typography>
                    </TextSection>
                    <div className="grid grid-cols-1 xl:grid-cols-2 mt-5 gap-y-6">
                        {itemsLeft?.map((item, index) => (
                            <PreviewCard
                                key={index}
                                mainImage={item.mainImage}
                                areaImages={item.areaImages}
                                urlTo={`/area/${item.id}`}
                                nameEl={item.nameEl}
                                nameEn={item.nameEn}
                            />
                        ))}
                    </div>
                </div>
                <div className="col-span-1">
                    <div className="grid grid-cols-1 xl:grid-cols-2 col-span-1 gap-y-6">
                        {itemsRight?.map((item, index) => (
                            <PreviewCard
                                key={index}
                                mainImage={item.mainImage}
                                areaImages={item.areaImages}
                                urlTo={`/area/${item.id}`}
                                nameEl={item.nameEl}
                                nameEn={item.nameEn}
                            />
                        ))}
                    </div>
                </div>
            </section>
        </Section>
    );
};

export default FourColumnGrid;
