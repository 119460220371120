import { createContext, useContext } from 'react';
import { action, makeObservable, observable, toJS, runInAction } from 'mobx';
import { getCategoryInfoByAreaId, getCategoryList, getSubCategoryList } from '../Api/CategoryAgent';

export default class CategoryStore {
    categoryList = [];

    subcategoryList = [];

    allSubcategories = [];

    constructor() {
        makeObservable(this, {
            categoryList: observable,
            subcategoryList: observable,
            allSubcategories: observable,
            getCategoryFullList: action,
            getCategoryInfoByAreaId: action,
            getSubCategoriesByCatId: action,
            getSubCategoryFullList: action,
            getPoisBySubCategoryId: action
        });

        this.getCategoryFullList();
        this.getSubCategoryFullList();
    }

    getCategoryFullList = async () => {
        this.categoryList = await getCategoryList();
        return this.categoryList;
    };

    getCategoryInfoByAreaId = (categoryId, areaId) => getCategoryInfoByAreaId(categoryId, areaId);
    // Subcategories/GetSubCategoryList

    getSubCategoryFullList = async () => {
        this.subcategoryList = await getSubCategoryList();
        // console.log('subcategory List', toJS(this.subcategoryList));
    };

    getSubCategoriesByCatId = (categoryId) => {
        this.allSubcategories = this.subcategoryList?.filter((el) => el.categoryId === Number(categoryId));
        return this.allSubcategories;
    };

    getPoisBySubCategoryId = (subCategoryId) => {
        // console.log('all subcategs', toJS(this.allSubcategories));
        const subs = this.allSubcategories.filter((el) => el.subCategoryId === subCategoryId);
        // console.log('final subcategs', toJS(subs));
        return subs;
    };
}
