import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { GoogleMap, useJsApiLoader, KmlLayer } from '@react-google-maps/api';
import { useEffect, useState, useCallback, memo } from 'react';
import { googleApiKey } from 'Config/constant';
import { toJS } from 'mobx';
import { useStore } from 'Stores/GlobalStore';

const containerStyle = {
    height: '70vh',
    width: '100%'
};

const KmlMap = ({ routes, area, zoom = 10 }) => {
    const navigate = useNavigate();
    const params = useParams();
    const { areasStore } = useStore();
    // const { areasPoisList } = areasStore;

    const areaId = params?.areaId;

    const [map, setMap] = useState(null);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: googleApiKey
    });

    useEffect(() => {
        if (map) {
            if (area) {
                map.setCenter({
                    lat: Number(area?.geoLat),
                    lng: Number(area?.geoLong)
                });
                map.setZoom(zoom);
            }
        }
    }, [map, area, zoom]);

    const onKmlClick = (route) => {
        if (!route || !areaId) {
            return;
        }

        navigate(`/area/${areaId}/routes/${route?.id}`, {
            state: { poi: toJS(route) }
        });
    };

    const onLoad = useCallback((map) => setMap(map), []);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    return isLoaded ? (
        <div className="rounded-lg">
            <GoogleMap mapContainerStyle={containerStyle} onLoad={onLoad} onUnmount={onUnmount}>
                {routes?.length > 0 &&
                    routes?.map((route, index) => (
                        <KmlLayer
                            key={index}
                            url={route?.routeMapFile}
                            options={{ preserveViewport: true, suppressInfoWindows: true }}
                            onClick={() => onKmlClick(route)}
                        />
                    ))}
            </GoogleMap>
        </div>
    ) : (
        <></>
    );
};

export default memo(KmlMap);
