import { action, makeObservable, observable, toJS } from 'mobx';
import { getExtendedPoiById, getPoiList, getPoisByAreaAndSubCategoryId } from '../Api/PoiAgent';

export default class PoiStore {
    poiDetails = [];

    subcategoryPois = [];

    allPois = [];

    constructor() {
        makeObservable(this, {
            allPois: observable,
            poiDetails: observable,
            subcategoryPois: observable,
            getAllPOIs: action,
            getExtendedPoiById: action,
            getPoisByAreaAndSubCategoryId: action
        });

        this.getAllPOIs();
    }

    getAllPOIs = async () => {
        // eslint-disable-next-line no-return-assign
        this.allPois = await getPoiList();
        return this.allPois;
    };

    getExtendedPoiById = async (poiId) => {
        this.poiDetails = (await getExtendedPoiById(poiId))?.extendedPoiDto;
        return this.poiDetails;
    };

    getPoisByAreaAndSubCategoryId = async (areaId, subcategoryId) => {
        // eslint-disable-next-line no-return-assign
        this.subcategoryPois = await getPoisByAreaAndSubCategoryId(areaId, subcategoryId);
        return this.subcategoryPois;
    };
}
