import { action, makeObservable, observable, runInAction } from 'mobx';
import { getPoiList } from '../Api/PoiAgent';
import { getCategoryList, getSubCategoryList } from 'Api/CategoryAgent';
import { getAllAreaImages, getAllAreas } from 'Api/AreaAgent';
import { getRouteList } from 'Api/RouteAgent';

export default class DataStore {
    loadingAppData = true;

    fetchError = null;

    areas = [];

    categories = [];

    pois = [];

    routes = [];

    subcategories = [];

    constructor() {
        makeObservable(this, {
            loadingAppData: observable,
            fetchError: observable,
            areas: observable,
            categories: observable,
            pois: observable,
            routes: observable,
            subcategories: observable,
            initializeData: action,
            fetchAllAreas: action,
            fetchAllCategories: action,
            fetchAllPOIs: action,
            fetchAllRoutes: action,
            fetchAllSubCategories: action
        });

        this.initializeData();
    }

    initializeData = async () => {
        await Promise.all([
            this.fetchAllAreas(),
            this.fetchAllCategories(),
            this.fetchAllPOIs(),
            this.fetchAllRoutes(),
            this.fetchAllSubCategories()
        ])
            .catch((error) => {
                console.error(error);
                runInAction(() => {
                    this.fetchError = error;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.loadingAppData = false;
                });
            });
    };

    fetchAllAreas = async () => {
        const areas = await getAllAreas();
        runInAction(() => {
            this.areas = areas;
        });
        return this.areas;
    };

    fetchAllAreaImages = async () => {
        const areaImages = await getAllAreaImages();
        runInAction(() => {
            this.areaImages = areaImages;
        });
        return this.areaImages;
    };

    fetchAllCategories = async () => {
        const categories = await getCategoryList();
        runInAction(() => {
            this.categories = categories;
        });
        return this.categories;
    };

    fetchAllPOIs = async () => {
        const pois = await getPoiList();
        runInAction(() => {
            this.pois = pois;
        });
        return this.pois;
    };

    fetchAllRoutes = async () => {
        const routes = await getRouteList();
        runInAction(() => {
            this.routes = routes;
        });
        return this.routes;
    };

    fetchAllSubCategories = async () => {
        const subcategories = await getSubCategoryList();
        runInAction(() => {
            this.subcategories = subcategories;
        });
        return this.subcategories;
    };
}
