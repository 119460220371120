import itinerary from 'assets/images/icons/itinerary.svg';
import theater from 'assets/images/icons/theater.svg';
import river from 'assets/images/icons/river.svg';
import { Typography } from '@mui/material';
import Section from 'Components/Section/Section';
import { useTranslation } from 'react-i18next';

const CategoryItem = ({ category, categoriesMap }) => (
    <div className="flex flex-column items-center">
        <div className="h-[180px] w-[180px] rounded-full flex flex-column justify-center items-center border-solid border-[10px] border-slate-400">
            <div className="bg-slate-400 bg-opacity-30 h-[150px] w-[150px] rounded-full flex flex-column justify-center items-center">
                <img src={categoriesMap?.[category?.id]?.icon} alt={categoriesMap?.[category?.id]?.label} />
            </div>
        </div>
        <Typography fontSize={40} fontWeight={600} color="common.white" className="mt-2">
            {category?.poiCount || 0}
        </Typography>
        <Typography fontSize={20} fontWeight={600} color="common.white" className="mt-2">
            {categoriesMap?.[category?.id]?.label}
        </Typography>
    </div>
);

const getBackgroundUrl = (imageUrl) => (imageUrl ? `url(${imageUrl})` : null);

const StatsBanner = ({ bgImage, items }) => {
    const { t } = useTranslation();
    const categoriesMap = {
        1: { icon: theater, label: t('common.PointsOfCulture') },
        2: { icon: river, label: t('common.PointsOfTourism') },
        3: { icon: itinerary, label: t('common.Routes') }
    };
    return (
        <Section style={{ backgroundImage: getBackgroundUrl(bgImage?.url) }}>
            <div className="h-[490px] bg-[#0F3354] bg-opacity-70 flex justify-evenly items-center w-full">
                {items?.map((item) => (
                    <CategoryItem key={item?.id} category={item} categoriesMap={categoriesMap} />
                ))}
            </div>
        </Section>
    );
};

export default StatsBanner;
