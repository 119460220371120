import Section from 'Components/Section/Section';
import SectionTitle from 'Components/SectionTitle/SectionTitle';
import TextSection from 'Components/TextSection/TextSection';
import { useTranslation } from 'react-i18next';

const ProjectSummary = () => {
    const { t, i18n } = useTranslation();
    const lang = i18n?.language;
    const aside =
        lang === 'el'
            ? 'Αειφόρος Ανάπτυξη Λιγότερο Αναπτυγμένων Περιοχών με την Δημιουργία Νέων Τουριστικών Πόρων και Προϊόντων μέσω Ανάλυσης, Τεκμηρίωσης, Μοντελοποίησης, Διαχείρισης και Διατήρησης Πολιτιστικού Αποθέματος με χρήση Εφαρμογών ΤΠΕ ΚΩΔΙΚΟΣ ΕΡΓΟΥ Τ2ΕΔΚ-01278'
            : 'Sustainable Development of Less Developed Areas by Creating New Tourism Resources and Products through Analysis, Documentation, Modeling, Management and Conservation of Cultural Inventory using ICT Applications PROJECT CODE T2EDK-01278';
    const textSection =
        lang === 'el' ? (
            <TextSection asideText={aside}>
                <h3 className="font-bold pb-2 leading-snug">
                    Αειφόρος Ανάπτυξη Λιγότερο Αναπτυγμένων Περιοχών με την Δημιουργία Νέων Τουριστικών Πόρων και Προϊόντων μέσω Ανάλυσης,
                    Τεκμηρίωσης, Μοντελοποίησης, Διαχείρισης και Διατήρησης Πολιτιστικού Αποθέματος με χρήση Εφαρμογών ΤΠΕ – ΑΕΙ
                </h3>
                <h5 className="text-left text-justify">
                    <p className="py-2 leading-normal">
                        Στόχος του έργου ΑΕΙ είναι ο σχεδιασμός της αειφόρου ανάπτυξης αντιπροσωπευτικών περιοχών, από τις λιγότερο
                        αναπτυγμένες, σε γειτνίαση με πύλες εισόδου στην χώρα ή με τουριστικά ανεπτυγμένες περιοχές, ώστε να προσανατολιστεί
                        σε αυτές το υπάρχον τουριστικό ρεύμα με πόλους έλξης νέους τουριστικούς πόρους και προϊόντα που θα προκύψουν από την
                        αναβάθμιση του πολιτιστικού και περιβαλλοντικού αποθέματος και από την ανάπτυξη και αξιοποίησή του μέσω εφαρμογών
                        ΤΠΕ.
                    </p>
                    <p className="py-2 leading-normal">
                        Η αξιοποίηση εφαρμογών ΤΠΕ μπορεί να συμβάλει γενικότερα στην ανάπτυξη τουριστικών πόρων και προϊόντων των περιοχών
                        με πολιτιστικό και περιβαλλοντικό απόθεμα με την αναβάθμισή του, μέσω της της ανάλυσης, τεκμηρίωσης, μοντελοποίησης
                        και διαχείρισής του, ώστε να προσδώσει προστιθέμενη αξία στην τοπική τουριστική ανάπτυξη.
                    </p>
                    <p className="py-2 leading-normal">
                        Στο πλαίσιο αυτό εξωτερικές οικονομίες, όπως του τουρισμού, επενδύονται σε μία ενδογενή ανάπτυξη, με όχημα μεταφοράς
                        του τουριστικού ρεύματος από τις πλέον αναπτυγμένες στις «ξεχασμένες» ή/και λιγότερο αναπτυγμένες περιοχές,
                        προωθώντας νέους πόλους έλξης σε αυτές από την αναβάθμιση του πολιτιστικού και περιβαλλοντικού αποθέματος.
                    </p>
                    <p className="py-2 leading-normal">
                        Αντιπροσωπευτικά παραδείγματα περιοχών που η ανάπτυξη τους μπορεί να έχει πιλοτικό χαρακτήρα διότι είναι βιώσιμα,
                        μεταφέρσιμα και χαρακτηριστικά παραδείγματα είναι:
                    </p>
                </h5>
            </TextSection>
        ) : (
            <TextSection asideText={aside}>
                <h3 className="font-bold pb-2 leading-snug">
                    Development of Less Developed Areas by Creating New Tourism Resources and Products through Analysis, Documentation,
                    Modeling, Management and Preservation of Cultural Inventory using ICT Applications
                </h3>
                <h5 className="text-left text-justify">
                    <p className="py-2 leading-normal">
                        The aim of the AEI project is to plan the sustainable development of representative areas, among the least
                        developed, in the vicinity of gateways to the country or tourist developed areas, in order to orientate in these the
                        existing tourist stream with poles of attraction new tourist resources and products that will arise from the
                        upgrading the cultural and environmental stock and from its development and utilization through ICT applications.
                    </p>
                    <p className="py-2 leading-normal">
                        The utilization of ICT applications can generally contribute to the development of tourism resources and products of
                        the regions with cultural and environmental stock with its upgrading, through the analysis, documentation, modeling
                        and its management, in order to provide added value to local tourism development.
                    </p>
                    <p className="py-2 leading-normal">
                        In this context, external economies, such as tourism, are invested in an endogenous development, with a transport
                        vehicle of the tourist flow from the most developed to the &quot;forgotten&quot; and/or less developed areas,
                        promoting new poles of attraction in them from upgrading the cultural and environmental stock.
                    </p>
                    <p className="py-2 leading-normal">
                        Representative examples of areas whose development can have a pilot character because they are sustainable,
                        transportable and characteristic examples are:
                    </p>
                </h5>
            </TextSection>
        );

    return (
        <Section id="project" className="py-5" padded>
            <SectionTitle firstText={t('common.FewWords')} secondText={t('common.ForTheProject')} />
            {textSection}
        </Section>
    );
};

export default ProjectSummary;
