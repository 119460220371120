import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PreviewCard = ({ mainImage, areaImages, urlTo, nameEl, nameEn, moreText = null, darken = false, icon }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const getBackgroundUrl = (imageUrl) => (imageUrl ? `url(${imageUrl})` : null);

    return (
        <Link
            className="relative h-[450px] block rounded-xl m-2 cursor-pointer hover:shadow-2xl preview-card"
            style={{
                backgroundImage: mainImage ? getBackgroundUrl(mainImage) : getBackgroundUrl(areaImages?.at[0]?.url),
                backgroundColor: theme.palette.customPrimary.main,
                color: 'inherit'
            }}
            to={urlTo}
        >
            {darken && <div className="absolute rounded-xl top-0 left-0 w-full h-full bg-black opacity-50" />}
            {icon && (
                <Box className="absolute" top={90} bottom={0} left={0} right={0} display="flex" alignItems="start" justifyContent="center">
                    <img src={icon} alt="Icon" />
                </Box>
            )}
            <div className="absolute bottom-5 left-5">
                <Typography className="mb-2" color="common.white" variant="h3">
                    {i18n.language === 'el' ? nameEl : nameEn}
                </Typography>
                <div className="flex items-center">
                    <Typography color="common.white" className="mr-2" variant="body2">
                        {moreText ?? t('common.More')}
                    </Typography>
                    <span className="text-white mb-[2px]">&#x27F6;</span>
                </div>
            </div>
        </Link>
    );
};

export default PreviewCard;
