import Carousel from 'react-bootstrap/Carousel';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

function MainCarousel({ imageGallery, opacity = 1, controls = true, indicators = true }) {
    const [images, setImages] = useState([]);

    useEffect(() => {
        setImages(imageGallery);
    }, [imageGallery]);

    return (
        <Carousel className="w-100" style={{ height: 'calc(100vh - 64px)' }} controls={controls} indicators={indicators}>
            {images?.map((image, index) => (
                <Carousel.Item className="h-full bg-black" key={index}>
                    <div
                        className="d-block w-100 m-auto h-full"
                        style={{
                            backgroundImage: `url(${image.url})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            opacity
                        }}
                    />
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default observer(MainCarousel);
