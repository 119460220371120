import LanguageSwitcher from './LanguageSwitcher';
import { useTheme } from '@mui/material/styles';
import { observer } from 'mobx-react';
import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Typography, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import logo from 'assets/images/dashboard/AEI_logo.png';
import { Fragment, useEffect, useState } from 'react';

const getPages = (lang) =>
    lang === 'el'
        ? [
              {
                  name: 'Αρχική',
                  ref: '/'
              },
              {
                  name: 'Το έργο',
                  ref: '/#project'
              },
              {
                  name: 'Περιοχές',
                  ref: '/#areas'
              },
              {
                  name: 'Επικοινωνία',
                  ref: '/#contact'
              }
          ]
        : [
              {
                  name: 'Home',
                  ref: '/'
              },
              {
                  name: 'The project',
                  ref: '/#project'
              },
              {
                  name: 'Areas',
                  ref: '/#areas'
              },
              {
                  name: 'Contact',
                  ref: '/#contact'
              }
          ];

const Header = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [pages, setPages] = useState(getPages(i18n.language));

    useEffect(() => {
        i18n.changeLanguage('el');
    }, []);

    useEffect(() => {
        setPages(getPages(i18n.language));
    }, [i18n.language]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static">
            <Divider
                flexItem
                orientation="horizontal"
                sx={{
                    backgroundColor: theme.palette.customSecondary.main,
                    height: '16px'
                }}
            />
            <Container
                maxWidth={false}
                sx={{
                    backgroundColor: theme.palette.background.default
                }}
            >
                <Toolbar disableGutters>
                    <Link to="/" className="hidden sm:flex">
                        <img src={logo} alt="AEI" style={{ height: '90px', width: '90px' }} />
                    </Link>
                    <Box
                        sx={{
                            display: {
                                xs: 'flex',
                                sm: 'none'
                            },
                            marginRight: '2px'
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="customPrimary"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                    <Typography
                                        component="a"
                                        onClick={() => navigate(page.ref)}
                                        href={page?.ref === '/' ? null : page?.ref}
                                        textAlign="center"
                                        color={theme.palette.customPrimary.main}
                                        sx={{ textDecoration: 'none' }}
                                        fontSize={18}
                                    >
                                        {page.name}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                        {/* <LanguageSwitcher /> */}
                    </Box>
                    <Link to="/" className="sm:hidden flex items-center justify-center flex-grow">
                        <img src={logo} alt="AEI" style={{ height: '90px', width: '90px' }} />
                    </Link>
                    <Box sx={{ flexGrow: 1, justifyContent: 'end', flexWrap: 'wrap', display: { xs: 'none', sm: 'flex' }, gap: '0.8rem' }}>
                        {pages.map((page, index) => (
                            <Fragment key={page.name}>
                                <Button
                                    key={page.name}
                                    color="customPrimary"
                                    onClick={() => navigate(page.ref)}
                                    href={page?.ref === '/' ? null : page?.ref}
                                    sx={{
                                        my: 2,
                                        display: 'block',
                                        textTransform: 'none',
                                        fontWeight: theme.typography.fontWeightRegular,
                                        fontSize: 18
                                    }}
                                >
                                    {page.name}
                                </Button>
                                {index !== pages.length - 1 && (
                                    <Divider
                                        orientation="vertical"
                                        variant="middle"
                                        flexItem
                                        sx={{
                                            my: 3,
                                            borderColor: theme.palette.customPrimary.main
                                        }}
                                    />
                                )}
                            </Fragment>
                        ))}
                        {/* <LanguageSwitcher /> */}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default observer(Header);
