import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from 'Pages/Guest/Dashboard';
import Area from 'Pages/Guest/Area/Area';
import Category from 'Pages/Guest/Category/Category';
import PoiDetails from 'Pages/Guest/PoiDetails/PoiDetails';
import RoutesPage from 'Pages/Guest/RoutesPage/RoutesPage';
import RouteDetails from 'Pages/Guest/RouteDetails/RouteDetails';
import Page from 'MainAppComponents/Page/Page';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import PrivacyPolicy from 'Pages/Guest/PrivacyPolicy/PrivacyPolicy';

const GuestRoutes = () => {
    const location = useLocation();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage('el');
    }, []);

    return (
        <Routes>
            <Route
                exact
                path="/"
                element={
                    <Page title={t('pages.home')}>
                        <Dashboard />
                    </Page>
                }
            />
            <Route
                exact
                path="/area/:areaId"
                element={
                    <Page>
                        <Area />
                    </Page>
                }
            />
            <Route
                exact
                path="/area/:areaId/category/:categoryId"
                element={
                    <Page>
                        <Category />
                    </Page>
                }
            />
            <Route
                exact
                path="/area/:areaId/category/:categoryId/poidetails/:id"
                element={
                    <Page>
                        <PoiDetails />
                    </Page>
                }
            />
            <Route
                exact
                path="/area/:areaId/routes"
                element={
                    <Page>
                        <RoutesPage />
                    </Page>
                }
            />
            <Route
                exact
                path="/area/:areaId/routes/:id"
                element={
                    <Page>
                        <RouteDetails />
                    </Page>
                }
            />
            <Route
                exact
                path="/privacy-policy"
                element={
                    <Page>
                        <PrivacyPolicy />
                    </Page>
                }
            />
            <Route path="*" element={<Navigate to="/" state={{ from: location }} />} />
        </Routes>
    );
};

export default GuestRoutes;
