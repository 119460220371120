import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Divider, Typography, useTheme } from '@mui/material';
import { useState } from 'react';

function LanguageSwitcher() {
    const theme = useTheme();
    const { t } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState('el');

    return (
        <Box sx={{ justifyContent: 'end', display: { xs: 'flex', sm: 'flex' } }}>
            <Button
                color="customPrimary"
                onClick={() => setSelectedLanguage('en')}
                sx={{
                    paddingX: '0px',
                    my: 2,
                    textTransform: 'none',
                    fontWeight: theme.typography.fontWeightRegular
                }}
            >
                <Typography fontSize={18} textAlign="center" fontWeight={selectedLanguage === 'en' ? 'bold' : 'normal'}>
                    EN
                </Typography>
            </Button>
            <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{
                    my: 3,
                    paddingX: '0px',
                    borderColor: theme.palette.customPrimary.main
                }}
            />
            <Button
                color="customPrimary"
                onClick={() => setSelectedLanguage('el')}
                sx={{
                    paddingX: '0px',
                    my: 2,
                    textTransform: 'none',
                    fontWeight: theme.typography.fontWeightRegular
                }}
            >
                <Typography fontSize={18} textAlign="center" fontWeight={selectedLanguage === 'el' ? 'bold' : 'normal'}>
                    ΕΛ
                </Typography>
            </Button>
        </Box>
    );
}

export default observer(LanguageSwitcher);
