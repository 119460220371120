import { Typography } from '@mui/material';

const SectionTitle = ({ className, firstText, secondText }) => (
    <div className={className || 'pb-5'}>
        <Typography variant="h2" color="customPrimary.main" className="inline" fontSize="50px">
            {firstText}
        </Typography>
        <span>&nbsp;&nbsp;</span>
        <Typography variant="h2" color="customSecondary.main" className="inline" fontSize="50px">
            {secondText}
        </Typography>
    </div>
);

export default SectionTitle;
