import { requests } from '../Common/HttpClient/HttpClient';

const { get } = requests;

export function getCategoryList() {
    return get('/api/Category/GetCategoryList');
}

// eslint-disable-next-line import/prefer-default-export
export function getCategoryInfoByAreaId(categoryId, areaId) {
    return get(`/api/Poi/${categoryId}/${areaId}`);
}

export function getSubCategoryList() {
    return get(`/api/Subcategory/GetSubCategoryList`);
}

export function getImageByPoiId(poiId) {
    return get(`/api/PoiImage/GetByPoiId/${poiId}`);
}
