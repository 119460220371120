import { createContext, useContext } from 'react';
import { action, makeObservable, observable, toJS, runInAction } from 'mobx';
import { getAllAreas, getPoisByAreaId, getByAreaAndCategoryId, getAreaByIdfromApi, getRoutesByAreaId } from '../Api/AreaAgent';
import { tourismCategoryID, cultureCategoryID, routesCategoryID } from '../Config/constant';
import CategoryStore from './CategoryStore';

export default class AreasStore {
    areasPoisList = [];

    routesList = [];

    areasList = [];

    politismos = [];

    diadromes = [];

    tourismos = [];

    area = {};

    category = {};

    storedAreaId = {};

    storedCategoryId = {};

    constructor() {
        makeObservable(this, {
            areasPoisList: observable,
            routesList: observable,
            areasList: observable,
            politismos: observable,
            diadromes: observable,
            tourismos: observable,
            area: observable,
            storedAreaId: observable,
            storedCategoryId: observable,
            getAllAreas: action,
            getAreaById: action,
            getCategoriesByAreaId: action,
            setCategoryAndArea: action,
            getByAreaAndCategoryId: action,
            getPoisBySubCategoryId: action,
            getAllCategoryPois: action,
            getRoutesBySubCategoryId: action
        });

        this.getAllAreas();
    }

    getAllAreas = async () => {
        this.areasList = await getAllAreas();
    };

    getAreaById = async (id) => {
        if (this.areasList.length !== 0) {
            this.area = id ? this.areasList?.find((el) => el.id === +id) : {};
        } else {
            await getAreaByIdfromApi(id).then((res) => {
                this.area = res?.extendedAreaDto;
            });
        }
    };

    getCategoriesByAreaId = async (areaId) => {
        this.areasPoisList = await getPoisByAreaId(areaId);
        this.politismos = this.areasPoisList?.extendedPoiListDto.filter((poi) => poi?.category.id === cultureCategoryID);
        this.tourismos = this.areasPoisList?.extendedPoiListDto.filter((poi) => poi?.category.id === tourismCategoryID);
    };

    getRoutesByAreaId = async (areaId) => {
        this.routesList = await getRoutesByAreaId(areaId);
        this.diadromes = this.routesList?.extendedRouteListDto;
    };

    getByAreaAndCategoryId = async (areaId, categoryId) => {
        const categoryPoisList = await getByAreaAndCategoryId(areaId, categoryId);
        return categoryPoisList;
    };

    getPoisBySubCategoryId = (subCategoryId) => {
        if (subCategoryId) {
            const subcategoryPois = this.areasPoisList?.extendedPoiListDto?.filter((el) => el?.subCategory.id === subCategoryId);
            return subcategoryPois;
        }
        return [];
    };

    getRoutesBySubCategoryId = (subCategoryId) => {
        if (subCategoryId) {
            const subcategoryRoutes = this.routesList?.extendedRouteListDto.filter((el) => el?.subCategory.id === subCategoryId);
            return toJS(subcategoryRoutes);
        }
        return [];
    };

    getAllCategoryPois = (categoryId) => {
        switch (categoryId) {
            case 1:
                return this.politismos;
            case 2:
                return this.tourismos;
            case 3:
                return this.diadromes;
            default:
                return [];
        }
    };

    setCategoryAndArea = (areaId, categoryId) => {
        this.storedCategoryId = categoryId;
        this.storedAreaId = areaId;
    };
}
