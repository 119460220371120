import { Grid, Typography } from '@mui/material';
import MainCarousel from 'Components/MainCarousel/MainCarousel';
import { useTheme } from '@mui/material/styles';

const MainBannerWithTitle = ({ mainTitle, upperTitle, imageGallery, controls = true, indicators = true }) => {
    const theme = useTheme();

    return (
        <>
            {imageGallery && imageGallery.length > 0 ? (
                <MainCarousel imageGallery={imageGallery} opacity={0.8} controls={controls} indicators={indicators} />
            ) : (
                <div className="w-100" style={{ height: 'calc(80vh - 64px)', backgroundColor: theme.palette.customPrimary.main }} />
            )}

            <Grid
                item
                xs={12}
                textAlign="center"
                style={{
                    position: 'absolute',
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1
                }}
            >
                <Typography
                    gutterBottom
                    variant="h3"
                    textTransform="uppercase"
                    letterSpacing={2}
                    fontWeight={400}
                    fontSize={34}
                    color={theme.palette.common.white}
                >
                    <span lang="el">{upperTitle}</span>
                </Typography>
                <Typography gutterBottom variant="h1" textTransform="uppercase" color={theme.palette.common.white} fontSize={60}>
                    <span lang="el">{mainTitle}</span>
                </Typography>
            </Grid>
        </>
    );
};

export default MainBannerWithTitle;
