import { capitalize } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GoogleMap, useJsApiLoader, InfoWindow, Marker } from '@react-google-maps/api';
import { useEffect, useState, useCallback, memo } from 'react';
import { googleApiKey } from 'Config/constant';
import { toJS } from 'mobx';

const containerStyle = {
    height: '60vh',
    width: '100%'
};

const Map = ({ geolocation, selectedSubcategoryPois, areaId, categoryId }) => {
    const { i18n } = useTranslation();
    const zoom = 10;
    const navigate = useNavigate();

    const [map, setMap] = useState(null);

    const [center] = useState({ lat: 0, lng: 0 });

    const [pois, setPois] = useState([]);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: googleApiKey
    });

    const [activeMarker, setActiveMarker] = useState(null);

    const handleActiveMarker = (markerId) => {
        if (markerId === activeMarker) {
            return;
        }
        setActiveMarker(markerId);
    };

    const handleClickMarker = (marker) => {
        if (marker) {
            const id = marker?.id;
            navigate(`/area/${areaId}/category/${categoryId}/poidetails/${id}`, { state: { poi: toJS(marker) } });
        }
    };

    useEffect(() => {
        setActiveMarker(null);
        setPois(selectedSubcategoryPois);
    }, [selectedSubcategoryPois]);

    useEffect(() => {
        if (map) {
            let bounds = new window.google.maps.LatLngBounds();
            bounds.extend({
                lat: +geolocation?.geoLat,
                lng: +geolocation?.geoLong
            });

            if (selectedSubcategoryPois && selectedSubcategoryPois?.length > 1) {
                bounds = new window.google.maps.LatLngBounds();
                // eslint-disable-next-line array-callback-return
                selectedSubcategoryPois?.map((marker) => {
                    bounds.extend({
                        lat: Number(marker.geoLat),
                        lng: Number(marker.geoLong)
                    });
                });
                map.fitBounds(bounds);
            } else {
                map.setCenter({
                    lat: +geolocation?.geoLat,
                    lng: +geolocation?.geoLong
                });
                map.setZoom(zoom);
            }
        }
    }, [map, selectedSubcategoryPois?.length]);

    const onLoad = useCallback((map) => setMap(map), []);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    return isLoaded ? (
        <div className="rounded-lg">
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onClick={() => setActiveMarker(null)}
            >
                {pois && pois?.length > 0
                    ? pois.map((marker) => (
                          <Marker
                              key={marker?.id}
                              position={{ lat: +marker?.geoLat, lng: +marker?.geoLong }}
                              onMouseOver={() => handleActiveMarker(marker?.id)}
                              onClick={() => handleClickMarker(marker)}
                          >
                              {activeMarker && activeMarker === marker?.id ? (
                                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                      <div>{marker?.[`name${capitalize(i18n.language)}`]}</div>
                                  </InfoWindow>
                              ) : null}
                          </Marker>
                      ))
                    : null}
            </GoogleMap>
        </div>
    ) : null;
};

export default memo(Map);
