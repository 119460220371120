import { requests } from '../Common/HttpClient/HttpClient';

const { get } = requests;

export function getPoiList() {
    return get('/api/Poi/GetPoiList');
}

// eslint-disable-next-line import/prefer-default-export
export function getExtendedPoiById(id) {
    return get(`/api/Poi/getExtendedPoiById/${id}`);
}

export function getPoisByAreaAndSubCategoryId(areaId, subCategotyId) {
    return get(`/api/Poi/GetByAreaAndSubCategoryId?areaId=${areaId}&subCategotyId=${subCategotyId}`);
}
